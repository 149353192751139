import { useState } from 'react';
import {
  Delete,
  FileDownload,
  FilterList,
  Refresh,
  Search as SearchIcon,
} from '@mui/icons-material';
import {
  alpha,
  Badge,
  Box,
  CircularProgress,
  IconButton,
  styled,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@mui/material';
import without from 'lodash/without';
import { ButtonAction, Resource } from '@/classes';
import ActionsRenderer from '@/components/Actions/ActionsRenderer';
import { useShowLoading } from '@/contexts/DialogContext';
import { useDialogs } from '@/contexts/DialogContext';
import useGetParamsForIndexRequest from '@/hooks/useGetParamsForIndexRequest';
import getApiUrl from '@/utils/getApiUrl';
import getValueFromEvent from '@/utils/getValueFromEvent';
import numString from '@/utils/numString';
import wrap from '@/utils/wrap';
import { NonFormField } from '../Form/FormField';
import ColumnsDialog from './ColumnsDialog';
import { useDataTable } from './DataTableContext';
import FilterDrawer from './FilterDrawer';
import Search from './Search';

const Toolbar = styled('div', {
  shouldForwardProp: (prop) => prop !== 'highlight',
})<{ highlight?: boolean }>(({ theme, highlight }) => ({
  minHeight: theme.spacing(10),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  paddingRight: theme.spacing(2),
  paddingLeft: theme.spacing(2),
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
  backgroundColor: highlight
    ? alpha(theme.palette.primary.main, 0.08)
    : theme.palette.background.paper,
}));

export default function DataTableToolbar({ resource }: { resource: Resource }) {
  const {
    columns,
    onColumnsChange,
    selected,
    isFetching,
    onReload,
    filters,
    onSetFilters,
    query,
    onQuery,
    sort,
    onFilter,
  } = useDataTable();
  const [searchOpen, setSearchOpen] = useState(!!query);
  const [filtersOpen, setFiltersOpen] = useState(false);
  const isMobile = useMediaQuery('(max-width: 900px)');
  const actionsArray = Array.isArray(resource.bulkActions)
    ? [...resource.bulkActions]
    : resource.bulkActions;
  const showLoading = useShowLoading();
  const { confirm } = useDialogs();
  const getParamsForIndexRequest = useGetParamsForIndexRequest();

  const showSearch = () => setSearchOpen(true);
  const onSearchBlur = () => {
    if (!query) {
      setSearchOpen(false);
    }
  };

  const toggleColumn = (fieldName: string) => {
    if (columns.indexOf(fieldName) > -1) {
      onColumnsChange(without(columns, fieldName));
    } else {
      onColumnsChange([...columns, fieldName]);
    }
  };

  const numSelected = wrap(selected).length;
  const showToolbar = numSelected > 0;
  const filterable = resource.getFilterableFields();
  const numActiveFilters = Object.entries(filters).filter(([f, value]) => {
    if (value == null) {
      return false;
    }
    if (filterable.find((i) => i.name === f)?.shouldQuickFilter(isMobile)) {
      return false;
    }
    return true;
  }).length;

  const getUrlForExport = () => {
    const params = getParamsForIndexRequest(
      resource,
      {
        filters,
        sort,
      },
      query,
    );
    const searchParams = new URLSearchParams({
      ...(params as Record<string, string>),
      format: 'xlsx',
    });
    return getApiUrl(resource.getApiEndpoint(), searchParams);
  };

  const handleDelete = () => {
    if (selected.length === 0) {
      return;
    }
    confirm({
      title: `Delete ${
        selected.length === 1 ? resource.singularName : numString(selected.length, resource.name)
      }`,
      description: 'Are you sure? This action cannot be undone.',
    }).then(() => {
      showLoading(Promise.all(selected.map((id) => resource.getDeleteRequest(id)))).then(() => {
        onReload();
      });
    });
  };

  if (resource.deletable && Array.isArray(actionsArray)) {
    actionsArray.push(new ButtonAction('Delete', handleDelete, Delete));
  }

  if (showToolbar) {
    return (
      <Toolbar highlight>
        <Typography color="inherit">{numSelected} selected</Typography>

        <div>
          <ActionsRenderer actions={actionsArray} resource={resource} />
        </div>
      </Toolbar>
    );
  }

  return (
    <Toolbar>
      <Box display="flex" alignItems="center">
        {isFetching ? (
          <Box p={1.5} display="inline-flex">
            <CircularProgress size={24} />
          </Box>
        ) : (
          <Tooltip title="Reload" placement="top">
            <IconButton onClick={onReload} size="large">
              <Refresh />
            </IconButton>
          </Tooltip>
        )}

        <ColumnsDialog
          allColumns={resource.columns}
          toggleColumn={toggleColumn}
          columns={columns}
        />

        {resource.canExport && (
          <Tooltip title="Export to Excel" placement="top">
            <IconButton component="a" href={getUrlForExport()} size="large">
              <FileDownload />
            </IconButton>
          </Tooltip>
        )}
      </Box>

      <Box display="flex" alignItems="center">
        {filterable
          .filter((i) => i.shouldQuickFilter(isMobile))
          .map((f) => (
            <Box key={f.name} mr={2} minWidth={225}>
              <NonFormField
                field={f.getFilterField().with({ margin: 'none' })}
                value={filters[f.name]}
                onChange={(e) => onFilter(f.name, getValueFromEvent(e))}
              />
            </Box>
          ))}

        {onQuery && (
          <div>
            {query || searchOpen ? (
              <Search value={query} onChange={onQuery} onBlur={onSearchBlur} />
            ) : (
              <IconButton onClick={showSearch} size="large">
                <SearchIcon />
              </IconButton>
            )}
          </div>
        )}

        {filterable.length > 0 && (
          <div>
            <Tooltip title="Filters" placement="top">
              <IconButton onClick={() => setFiltersOpen(true)} size="large">
                <Badge
                  badgeContent={numActiveFilters}
                  color="secondary"
                  invisible={numActiveFilters === 0}
                >
                  <FilterList />
                </Badge>
              </IconButton>
            </Tooltip>
          </div>
        )}
      </Box>

      <FilterDrawer
        open={filtersOpen}
        onClose={() => setFiltersOpen(false)}
        filterableFields={filterable.filter((i) => !i.shouldQuickFilter(isMobile))}
        onSuccess={onSetFilters}
        initialValues={filters}
      />
    </Toolbar>
  );
}
