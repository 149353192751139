import { SyntheticEvent } from 'react';
import {
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Checkbox,
  LinearProgress,
  Box,
} from '@mui/material';
import pickBy from 'lodash/pickBy';
import startCase from 'lodash/startCase';
import startsWith from 'lodash/startsWith';
import trimStart from 'lodash/trimStart';
import { Field, Resource, ButtonAction } from '@/classes';
import SubMenu from '@/components/Shared/SubMenu';
import wrap from '@/utils/wrap';
import { useDataTable } from './DataTableContext';

export default function DataTableHead({ resource }: { resource: Resource }) {
  const {
    visibleColumns,
    records,
    onSelected,
    sort,
    selected,
    showCheckbox,
    aggregations,
    onAggregation,
    meta,
    onSortChange,
    isFetching,
  } = useDataTable();

  const numSelected = selected.length;
  const rowCount = records.length;
  const sortDirection = startsWith(sort || '', '-') ? 'desc' : 'asc';
  const withoutDirection = trimStart(sort || '', '-');
  const aggregationResults = pickBy(meta || {}, (v, k) => /(_sum|_avg)$/.test(k));

  const createSortHandler = (columnName: string) => () => onSortChange(columnName);
  const getSum = (f: Field) => aggregationResults[`${f.name}_sum`];
  const getAvg = (f: Field) => aggregationResults[`${f.name}_avg`];

  const onSelectAllClick = (event: SyntheticEvent, checked: boolean) => {
    onSelected(checked ? records.map((n) => n[resource.primaryKey]) : []);
  };

  return (
    <TableHead>
      <TableRow>
        {showCheckbox && (
          <TableCell padding="checkbox">
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={numSelected > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
            />
          </TableCell>
        )}
        {visibleColumns.map((f) => {
          const toggleText = (type: 'avg' | 'sum') => {
            const toggled = wrap(aggregations[type]).includes(f.name);
            return `${toggled ? 'Hide' : 'Show'} ${startCase(type)}`;
          };

          const subMenu = f.aggregatable ? (
            <SubMenu
              sx={{ ml: 1 }}
              size="small"
              items={[
                new ButtonAction(toggleText('sum'), () => onAggregation(f.name, 'sum')),
                new ButtonAction(toggleText('avg'), () => onAggregation(f.name, 'avg')),
              ]}
            />
          ) : null;
          const currentDirection = withoutDirection === f.sortableBy ? sortDirection : undefined;

          return (
            <TableCell
              key={f.name}
              sortDirection={currentDirection}
              style={{ whiteSpace: 'nowrap' }}
            >
              {f.sortableBy ? (
                <TableSortLabel
                  direction={currentDirection}
                  onClick={createSortHandler(f.sortableBy)}
                  active={withoutDirection === f.sortableBy}
                >
                  {f.label}
                </TableSortLabel>
              ) : (
                f.label
              )}
              {subMenu}
            </TableCell>
          );
        })}
      </TableRow>
      {Object.keys(aggregationResults).length > 0 && (
        <TableRow>
          {showCheckbox && <TableCell />}
          {visibleColumns.map((f) => (
            <TableCell key={f.name} variant="footer" style={{ whiteSpace: 'nowrap' }}>
              {getSum(f) && <div>Sum: {f.renderCell(getSum(f))}</div>}
              {getAvg(f) && <div>Avg: {f.renderCell(getAvg(f))}</div>}
            </TableCell>
          ))}
        </TableRow>
      )}
      <TableRow>
        <TableCell
          colSpan={visibleColumns.length + Number(showCheckbox)}
          sx={{
            p: 0,
            position: 'relative',
            height: 1,
            border: 0,
          }}
        >
          {isFetching && (
            <Box position="absolute" top={-4} right={0} left={0}>
              <LinearProgress />
            </Box>
          )}
        </TableCell>
      </TableRow>
    </TableHead>
  );
}
