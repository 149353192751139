import { useCallback, useEffect, useRef } from 'react';
import { Alert, Box, CircularProgress, Paper } from '@mui/material';
import isNil from 'lodash/isNil';
import omitBy from 'lodash/omitBy';
import pick from 'lodash/pick';
import hash from 'object-hash';
import type { FieldValues } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Resource } from '@/classes';
import CardLayout from '@/classes/Layouts/CardLayout';
import StackLayout from '@/classes/Layouts/StackLayout';
import IndexAppBarActions from '@/components/Actions/IndexAppBarActions';
import SingleAppBarActions from '@/components/Actions/SingleAppBarActions';
import CreateButton from '@/components/Buttons/CreateButton';
import DataTable, { DataTableHandle } from '@/components/DataTable/DataTable';
import { DataTableState } from '@/components/DataTable/DataTableContext';
import EditForm from '@/components/Form/EditForm';
import ClosableDrawer from '@/components/Shared/ClosableDrawer';
import ShippingScanDetection from '@/components/Shipping/ShippingScanDetection';
import { useAppContext } from '@/contexts/AppContext';
import { useToast } from '@/contexts/DialogContext';
import { useResource } from '@/hooks/useGetResource';
import useSearchParamsObject from '@/hooks/useSearchParamsObject';
import { Page, ResourceName, ResourceValueMap } from '@/resources/types';
import { useMode, useResourceQuery, useUpdateRecord } from '@/utils/genericResource';

export default function GenericResource<K extends ResourceName>({ page }: { page: Page<K> }) {
  if (!page.resource) {
    throw new Error('A page using GenericResource must have a resource defined');
  }
  const resource = useResource(page.resource) as Resource<ResourceValueMap[K]>;
  const { primaryKey } = resource;
  const [mode, paramId] = useMode();
  const { data: record, isLoading, isError } = useResourceQuery(page.resource);
  const updateRecord = useUpdateRecord(page.resource);

  const navigate = useNavigate();
  const toast = useToast();
  const [searchParams, setSearchParams] = useSearchParamsObject();
  const { setAppBarState } = useAppContext();
  const dataTableRef = useRef<DataTableHandle | null>(null);
  const latestDataTableState = useRef<DataTableState>();

  const isCreate = mode === 'create';

  const closeDrawer = useCallback(() => navigate(`${page.href}`), [navigate, page.href]);
  const create = useCallback(() => navigate(`${page.href}/create`), [navigate, page.href]);

  const reloadTable = useCallback(() => {
    dataTableRef.current?.onReload();
  }, [dataTableRef]);

  const closeAndReload = () => {
    closeDrawer();
    reloadTable();
  };

  const getSingleActions = () => {
    if (mode === 'edit' && record) {
      return <SingleAppBarActions resource={resource} values={record} onDeleted={closeAndReload} />;
    }
    return null;
  };

  const getSingleBody = () => {
    if (mode === 'index') {
      return null;
    }
    if (mode === 'edit') {
      if (isError) {
        return (
          <Alert severity="error">
            {resource.singularName} #{paramId} not found
          </Alert>
        );
      }

      if (isLoading || !record) {
        return (
          <div>
            <CircularProgress />
          </div>
        );
      }
    }
    return (
      <EditForm
        resource={resource}
        onSuccess={onUpdated}
        initialValues={defaultValues}
        defaultLayout={resource.useDrawer ? StackLayout : CardLayout}
        isCreate={isCreate}
        allowPrevNext={mode === 'edit'}
      />
    );
  };

  useEffect(() => {
    if (mode === 'index') {
      setAppBarState({
        title: page.title || page.name,
        back: null,
        actions: <IndexAppBarActions resource={resource} />,
      });
    } else if (resource.useDrawer) {
      // Do nothing
    } else if (mode === 'create') {
      setAppBarState({
        title: `Create ${resource.singularName}`,
        back: page.href,
      });
    } else {
      setAppBarState({
        title: record ? resource.getTitle(record as ResourceValueMap[K]) : '',
        back: page.href,
        actions: getSingleActions(),
      });
    }
  }, [mode, record, resource, setAppBarState]);

  const onUpdated = (data: FieldValues) => {
    toast('Successfully saved', 'success');
    updateRecord(data as ResourceValueMap[K]);
    if (resource.useDrawer) {
      closeAndReload();
    } else if (isCreate || (paramId && String(data[primaryKey]) != paramId)) {
      navigate(`${page.href}/${data[primaryKey]}`);
    }
  };

  const defaultValues = record || resource.defaultValues;

  if (mode !== 'index' && !resource.useDrawer) {
    return getSingleBody();
  }

  const drawer = resource.useDrawer && (resource.editable || resource.creatable) && (
    <ClosableDrawer
      open={mode !== 'index'}
      closeOnClickOutside={false}
      onClose={closeDrawer}
      title={`${isCreate ? 'Create' : 'Edit'} ${resource.singularName}`}
      icons={getSingleActions()}
    >
      {mode !== 'index' && getSingleBody()}
    </ClosableDrawer>
  );

  const sessionKey = `latestTableState.${resource.key}.${hash(resource.getColumnNames())}`;
  const fromQueryDefaults = {
    query: '',
    sort: resource.defaultSort,
    count: 25,
    page: 1,
  };
  const keysInQuery = Object.keys(fromQueryDefaults);
  const keysNotInQuery = ['filters', 'query', 'columns'];

  const onStateUpdated = (state: DataTableState) => {
    latestDataTableState.current = state;
    setSearchParams(omitBy(pick(state, keysInQuery), isNil) as Record<string, string>, {
      replace: true,
    });
    sessionStorage.setItem(sessionKey, JSON.stringify(pick(state, keysNotInQuery)));
  };

  const loadFromSession = () => JSON.parse(sessionStorage.getItem(sessionKey) || '{}');

  const initialState: Partial<DataTableState> = {
    ...fromQueryDefaults,
    ...pick(latestDataTableState.current || loadFromSession(), keysNotInQuery),
    ...pick(searchParams, keysInQuery),
  };

  const defaultGetHref = (row: any) => `${page.href}/${row[primaryKey]}`;

  return (
    <Box pb={9}>
      <Paper>
        <DataTable
          ref={dataTableRef}
          getHref={page.getHref || defaultGetHref}
          resource={resource}
          initialState={initialState}
          onStateUpdated={onStateUpdated}
        />

        {resource.creatable && <CreateButton onClick={create} />}
        {drawer}

        {page.resource === 'ordersToShip' && <ShippingScanDetection />}
      </Paper>
    </Box>
  );
}
