import { useState } from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Close as CloseIcon, DragHandle, Edit, ImageSearch, Link, Undo } from '@mui/icons-material';
import { Avatar, Box, IconButton, TableCell, TableRow, Tooltip, Typography } from '@mui/material';
import get from 'lodash/get';
import pick from 'lodash/pick';
import moment from 'moment';
import { primaryColor } from '@/MuiProvider';
import { useDeleteOrderDesign, useUncompleteDesign, useUpdateOrderDesign } from '@/api/designs';
import { FieldFactory } from '@/classes';
import ArtRequestActivityDrawer from '@/components/ArtRequests/ArtRequestActivityDrawer';
import ArtRequestDesignDrawer from '@/components/ArtRequests/ArtRequestDesignDrawer';
import InkChangeField from '@/components/Designs/InkChangeField';
import ImprintsDrawer from '@/components/Orders/Art/ImprintsDrawer';
import RequestedDesignDrawer from '@/components/Orders/Art/RequestedDesignDrawer';
import StatusChip from '@/components/Shared/StatusChip';
import WarningIcon from '@/components/Shared/WarningIcon';
import TextButton from '@/components/Text/TextButton';
import { useShowLoading } from '@/contexts/DialogContext';
import { useDialogs } from '@/contexts/DialogContext';
import { OrderDesign, orderDesignUpdatePayloadSchema } from '@/types';
import { maybeAddQueryParams } from '@/utils/query';
import OrderDesignLabel from '../Designs/OrderDesignLabel';

export default function OrderDesignListItem({
  orderDesign,
  artRequestId,
  canEdit,
  sortable,
}: {
  orderDesign: OrderDesign;
  artRequestId: number | undefined;
  canEdit: boolean;
  sortable?: boolean;
}) {
  const { design } = orderDesign;

  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: orderDesign.id,
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const { prompt, confirm } = useDialogs();
  const showLoading = useShowLoading();

  const updateRequest = useUpdateOrderDesign(orderDesign);
  const uncompleteRequest = useUncompleteDesign(orderDesign);
  const deleteRequest = useDeleteOrderDesign(orderDesign);

  const [editingRequestedDesign, setEditingRequestedDesign] = useState(
    !artRequestId &&
      !design.completed_at &&
      moment(orderDesign.created_at).isAfter(moment().subtract(5, 'seconds')),
  );
  const [editingDesign, setEditingDesign] = useState(false);
  const [editingImprints, setEditingImprints] = useState(false);
  const [showingArtActivities, setShowingArtActivities] = useState(false);

  const onEdit = () => {
    prompt({
      title: 'Edit Linked Design',
      fields: [
        FieldFactory.belongsTo('design', 'designs'),
        FieldFactory.custom('ink_changes', InkChangeField).withPermission('write:designs'),
      ],
      initialValues: pick(orderDesign, ['design', 'ink_changes']),
      schema: orderDesignUpdatePayloadSchema,
      onSubmit: (v) => updateRequest.mutateAsync(v),
    });
  };

  const onRemove = () => {
    confirm({
      title: 'Remove Design From Layout',
      description: 'Are you sure you want to remove this design from this layout?',
      color: 'error',
    }).then(() => {
      showLoading(deleteRequest.mutateAsync());
    });
  };

  return (
    <TableRow key={design.id} ref={setNodeRef} style={style}>
      <TableCell style={{ width: 90 }}>
        <Avatar
          variant="square"
          component={design.image ? 'a' : 'div'}
          href={design.image || undefined}
          target="_blank"
          src={maybeAddQueryParams(design.image, { w: 200 })}
          alt={design.increment_id}
          style={{
            width: 56,
            height: 56,
            background: primaryColor,
            color: 'white',
          }}
        >
          {design.increment_id.substr(-2)}
        </Avatar>
      </TableCell>
      <TableCell style={{ width: 160 }}>
        <OrderDesignLabel orderDesign={orderDesign} />
        <StatusChip
          status={design.status}
          colors={{
            Complete: 'green',
            Incomplete: 'amber',
          }}
          style={{
            padding: 0,
            marginLeft: 0,
            marginRight: 0,
            marginTop: 4,
            height: 26,
          }}
        />
      </TableCell>
      <TableCell style={{ minWidth: 200 }}>
        <Typography variant="body2">{design.name}</Typography>
        <Typography variant="body2" color="textSecondary">
          {design.location}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body2">{get(design, 'decoration_type.name')}</Typography>
        <Typography variant="body2" color="textSecondary">
          {design.complexity_label}
        </Typography>
      </TableCell>
      <TableCell style={{ whiteSpace: 'nowrap' }}>
        <TextButton onClick={() => setEditingImprints(true)}>
          {orderDesign.imprint_qty === 0 ? (
            <WarningIcon message="This decoration will not be applied to any items" />
          ) : null}
          <span>{`${orderDesign.imprint_qty} Imprints`}</span>
        </TextButton>
      </TableCell>
      <TableCell style={{ whiteSpace: 'nowrap' }}>
        <Box display="flex" alignItems="center">
          {artRequestId ? (
            <>
              <Tooltip title="Edit Linked Design">
                <IconButton onClick={onEdit}>
                  <Link />
                </IconButton>
              </Tooltip>

              {design.completed_at ? (
                <Tooltip title="Mark As Incomplete (will make editable)">
                  <IconButton
                    onClick={() => uncompleteRequest.mutate(artRequestId)}
                    disabled={uncompleteRequest.isLoading}
                  >
                    <Undo />
                  </IconButton>
                </Tooltip>
              ) : (
                <Tooltip title="Edit Design">
                  <IconButton onClick={() => setEditingDesign(true)}>
                    <Edit />
                  </IconButton>
                </Tooltip>
              )}
              <Tooltip title="Art Activities">
                <IconButton onClick={() => setShowingArtActivities(true)}>
                  <ImageSearch />
                </IconButton>
              </Tooltip>
            </>
          ) : canEdit ? (
            <>
              <Tooltip title="Edit Linked Design">
                <IconButton onClick={onEdit}>
                  <Link />
                </IconButton>
              </Tooltip>

              {!design.completed_at ? (
                <Tooltip title="Edit Requested Design">
                  <IconButton onClick={() => setEditingRequestedDesign(true)}>
                    <Edit />
                  </IconButton>
                </Tooltip>
              ) : null}

              <Tooltip title="Remove design from layout">
                <IconButton onClick={onRemove}>
                  <CloseIcon />
                </IconButton>
              </Tooltip>
            </>
          ) : null}

          {sortable && (
            <Box p={1} {...attributes} {...listeners}>
              <DragHandle style={{ verticalAlign: 'middle' }} />
            </Box>
          )}
        </Box>
      </TableCell>
      <ImprintsDrawer
        orderDesign={orderDesign}
        open={editingImprints}
        onClose={() => setEditingImprints(false)}
      />
      ;
      {artRequestId ? (
        <>
          <ArtRequestDesignDrawer
            artRequestId={artRequestId}
            orderDesign={orderDesign}
            open={editingDesign}
            onClose={() => setEditingDesign(false)}
          />
          <ArtRequestActivityDrawer
            artRequestId={artRequestId}
            design={orderDesign.design}
            open={showingArtActivities}
            onClose={() => setShowingArtActivities(false)}
          />
        </>
      ) : (
        <RequestedDesignDrawer
          orderDesign={orderDesign}
          open={editingRequestedDesign}
          onClose={() => setEditingRequestedDesign(false)}
        />
      )}
    </TableRow>
  );
}
