import { z } from 'zod';
import { GetIssuesParams } from '@/api/issues';
import { Field, Resource } from '@/classes';
import FieldFactory from '@/classes/FieldFactory';
import IssuesPage from '@/components/Issues/IssuesPage';
import { ISSUE_STATUS_COLORS } from '@/constants';
import { Issue, issuePayloadSchema, genericModelReferenceSchema } from '@/types';
import { buildIncrementId } from '@/utils/notes';

export function getIssueFields(params: GetIssuesParams, orderIds?: number[]) {
  const fields: Field[] = [
    FieldFactory.select('reason', ['Damaged', 'Missing', 'Wrong Item', 'Extras', 'Other']),
    FieldFactory.textarea('details'),
    FieldFactory.list('tracking_numbers', [FieldFactory.text('').withColumnSpan(10)]).with({
      addNewValue: '',
      addNewLabel: 'Add Tracking Number',
    }),
    FieldFactory.text('fields.from').withLabel('From'),
    FieldFactory.text('fields.to').withLabel('To'),
    FieldFactory.select('location', ['A', 'B', 'C', 'D', 'E', 'F']),
  ];

  if (params.purchaseOrderId) {
    fields.push(
      FieldFactory.hasMany('purchase_order_items', 'purchaseOrderItems').withRequestParams({
        'filter[purchase_order_id]': params.purchaseOrderId,
      }),
    );
  } else if (params.orderId) {
    fields.push(
      FieldFactory.hasMany('purchase_order_items', 'purchaseOrderItems').withRequestParams({
        'filter[order_item.order_id]': params.orderId,
      }),
    );
  }

  if (!params.orderId) {
    if (orderIds) {
      fields.unshift(
        FieldFactory.select(
          'order_id',
          orderIds.map((o) => ({
            value: o,
            label: buildIncrementId('SO', o),
          })),
        ).withLabel('Order'),
      );
    } else {
      fields.unshift(FieldFactory.belongsTo('order', 'orders'));
    }
  }

  if (!params.purchaseOrderId) {
    fields.unshift(FieldFactory.belongsTo('purchase_order', 'purchaseOrders'));
  }

  return fields;
}

export function getIssueSchema() {
  return issuePayloadSchema
    .extend({
      type: z.union([z.literal('receiving'), z.literal('purchasing')]),
      order_id: z.number().nullish(),
      purchase_order_id: z.number().nullish(),
      purchase_order: genericModelReferenceSchema.nullish(),
      order: genericModelReferenceSchema.nullish(),
      purchase_order_items: z.array(z.object({ id: z.number() })).nullish(),
    })
    .transform((v) => ({
      purchase_order_id: v.purchase_order?.id,
      order_id: v.order?.id,
      purchase_order_item_ids: v.purchase_order_items?.map((i) => i.id),
      ...v,
    }));
}

export default function receivingIssues() {
  return new Resource<Issue>('Receiving Issues')
    .getTitleUsing((issue) => issue.label)
    .editUsing(IssuesPage)
    .withApiEndpoint('/api/issues')
    .withDefaultSort('-id')
    .withSchema(getIssueSchema())
    .withDefaultValues({
      type: 'receiving',
    })
    .withQueryParams({
      index: {
        type: 'receiving',
      },
    })
    .withDefaultFilters({
      bucket: 'open',
    })
    .withFilters([
      FieldFactory.radio('bucket', [
        {
          value: 'open',
          label: 'Open',
        },
        {
          value: 'all',
          label: 'All',
        },
      ]).asQuickFilter(),
    ])
    .withColumns([
      FieldFactory.text('id').withLabel('#').sortable(),
      FieldFactory.timestamp('created_at').sortable(),
      FieldFactory.text('order.increment_id').withLabel('Order').sortable(),
      FieldFactory.text('purchase_order.increment_id').withLabel('Purchase Order').sortable(),
      FieldFactory.text('preview').sortable('details'),
      FieldFactory.text('reason'),
      FieldFactory.status('status', ISSUE_STATUS_COLORS).sortable(),
    ])
    .withFields(
      getIssueFields({
        type: 'receiving',
      }),
    );
}
