import { useState } from 'react';
import { Add, ArrowForward, Delete, ExpandMore } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Box,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  LinearProgress,
  Typography,
} from '@mui/material';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { useGetOrderPackouts } from '@/api/kitting';
import AddressDrawer from '@/components/Addresses/AddressDrawer';
import PackoutTimingTable from '@/components/Packouts/PackoutTimingTable';
import Can from '@/components/Permissions/Can';
import StatusChip from '@/components/Shared/StatusChip';
import { PACKOUT_READY_STATUS_COLOR, PACKOUT_STATUS_COLOR } from '@/constants';
import { AddressPayload, Packout } from '@/types';
import { useOnReloadRecord, useRecord } from '@/utils/genericResource';

export default function OrderPackouts() {
  const order = useRecord('orders');
  const reloadOrder = useOnReloadRecord('orders');
  const [deleting, setDeleting] = useState<Packout>();

  const { data: packouts, refetch, isFetching } = useGetOrderPackouts(order.id);

  const onCreatePackout = () => {
    axios.post('/api/order-packouts', { order_id: order.id }).then(() => {
      refetch();
    });
  };

  const onDeletePackout = (values: AddressPayload) => {
    return axios
      .post(`/api/order-packouts/${deleting!.id}/delete`, { address: values })
      .then(() => {
        setDeleting(undefined);
        refetch();
        reloadOrder();
      });
  };

  const createButton = (
    <IconButton onClick={onCreatePackout} size="large">
      <Add />
    </IconButton>
  );

  return (
    <Card>
      {isFetching && <LinearProgress />}

      {packouts && packouts.length === 0 ? (
        <>
          <CardHeader action={createButton} title="Packouts" />
          <CardContent>
            <Typography color="textSecondary">There are no packouts for this order.</Typography>
          </CardContent>
        </>
      ) : null}

      {packouts &&
        packouts.map((packout) => (
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMore sx={{ ml: 1 }} />}>
              <Box display="flex" alignItems="center" gap={1}>
                <Typography>Packout #{packout.id}</Typography>
                <Can permission="write:packouts">
                  <IconButton component={Link} to={`/packouts/${packout.id}`}>
                    <ArrowForward />
                  </IconButton>
                </Can>
              </Box>

              <Box ml="auto" display="flex" alignItems="center" gap={1}>
                {packout.status === 'pending' ? (
                  <StatusChip
                    status={packout.ready_status}
                    size="small"
                    colors={PACKOUT_READY_STATUS_COLOR}
                  />
                ) : (
                  <StatusChip status={packout.status} size="small" colors={PACKOUT_STATUS_COLOR} />
                )}

                {!order.invoiced_at && packout.status === 'pending' && (
                  <Can permission="write:shipments">
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation();
                        setDeleting(packout);
                      }}
                    >
                      <Delete />
                    </IconButton>
                  </Can>
                )}
              </Box>
            </AccordionSummary>
            <AccordionDetails sx={{ p: 0 }}>
              <PackoutTimingTable packout={packout} />
            </AccordionDetails>
          </Accordion>
        ))}

      <AddressDrawer
        open={!!deleting}
        onClose={() => setDeleting(undefined)}
        onSubmit={onDeletePackout}
        initialValues={{}}
        title="Delete Packout"
        description={
          <Alert severity="warning" sx={{ mb: 2, mt: -2 }}>
            This will make all the child orders shippable and charge a fee so the LOB can still use
            the packout paperwork. This action is not reversible. If you want to proceed, please
            enter an address to ship the production orders to (instead of the Packout room) below.
          </Alert>
        }
        excludeFields={['is_taxable_address', 'is_billing']}
      />
    </Card>
  );
}
