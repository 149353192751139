import { useState } from 'react';
import { ViewColumn } from '@mui/icons-material';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  Tooltip,
} from '@mui/material';

export default function ColumnsDialog({
  allColumns,
  toggleColumn,
  columns,
}: {
  allColumns: {
    name: string;
    label?: string;
  }[];
  toggleColumn: (name: string) => void;
  columns: string[];
}) {
  const [columnsOpen, setColumnsOpen] = useState(false);

  return (
    <>
      <Tooltip title="Show/Hide Columns" placement="top">
        <IconButton onClick={() => setColumnsOpen(true)} size="large">
          <ViewColumn />
        </IconButton>
      </Tooltip>

      <Dialog open={columnsOpen} onClose={() => setColumnsOpen(false)}>
        <DialogTitle id="dialog-title">Visible Columns</DialogTitle>

        <DialogContent
          style={{
            width: 600,
            maxWidth: '100%',
          }}
        >
          {allColumns.map((f) => (
            <div key={f.name}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={columns.includes(f.name)}
                    onChange={() => toggleColumn(f.name)}
                  />
                }
                label={f.label || f.name}
              />
            </div>
          ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setColumnsOpen(false)}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
