import { FieldFactory, Resource } from '@/classes';
import InventoryReturnPage from '@/components/Inventory/InventoryReturnPage';
import { RETURN_STATUS_COLORS } from '@/constants';
import { InventoryReturn } from '@/types';

export default function inventoryReturns() {
  return new Resource<InventoryReturn>('Inventory Returns')
    .withRelations(['items'])
    .setReadOnly(true)
    .getLabelUsing((v) => v.number)
    .getTitleUsing((v) => v.number)
    .withDefaultSort('-id')
    .withDefaultFilters({
      status: 'awaiting',
    })
    .withColumns([
      FieldFactory.text('id').withLabel('#').sortable(),
      FieldFactory.text('number').sortable(),
      FieldFactory.status('status', RETURN_STATUS_COLORS).quickFilterable(),
      FieldFactory.belongsTo('fulfillment_order', 'fulfillmentOrders').sortable(
        'fulfillment_order_id',
      ),
      FieldFactory.timestamp('created_at'),
    ])
    .editUsing(InventoryReturnPage);
}
