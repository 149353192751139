import { ElementType, MouseEventHandler, useState } from 'react';
import MoreIcon from '@mui/icons-material/MoreVert';
import { Divider, IconButton, Menu, SxProps } from '@mui/material';
import Action from '@/classes/Action';
import DividerModel from '@/classes/Actions/Divider';
import ActionMenuItem from '@/components/Actions/ActionMenuItem';
import { useRequiresPermissionAndRole } from '@/hooks/permissions';

type NullableAction = Action | null | undefined | false;

const getActions = (items: NullableAction[]) =>
  items.filter((i) => i instanceof Action) as Action[];

export default function SubMenu({
  items,
  size = 'medium',
  primaryIcon: PrimaryIcon = MoreIcon,
  sx = {},
}: {
  items: NullableAction[];
  size?: 'small' | 'medium';
  primaryIcon?: ElementType;
  sx?: SxProps;
}) {
  const requiresPermissionAndRole = useRequiresPermissionAndRole();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const onOpen: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setAnchorEl(e.currentTarget);
  };
  const onClose = () => setAnchorEl(null);

  return (
    <>
      <IconButton onClick={onOpen} size={size} sx={sx}>
        <PrimaryIcon fontSize={size} />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={onClose}
        onClick={onClose}
      >
        {getActions(items)
          .filter(requiresPermissionAndRole)
          .map((action) => {
            if (action instanceof DividerModel) {
              return <Divider key={action.label} sx={{ my: 1 }} />;
            }
            return <ActionMenuItem action={action} key={action.label} />;
          })}
      </Menu>
    </>
  );
}
