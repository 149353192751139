import { useLocation } from 'react-router-dom';
import { useRecordId } from '@/utils/genericResource';

export default function useApiSegment(index: number) {
  const location = useLocation();
  return location.pathname.split('/')[index];
}

export function useOrderableApiUrl(resource = '') {
  const id = useRecordId();
  const segment = useApiSegment(1);

  return `/api/${segment}/${id}/${resource}`.replace(/\/$/, '');
}
