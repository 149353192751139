import { ChangeEvent, useState } from 'react';
import { Alert, Box, Button, LinearProgress, Tab, Tabs } from '@mui/material';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { StringParam, useQueryParam, withDefault } from 'use-query-params';
import { AccountDetail } from '@/types';
import BankTransactionTable from './BankTransactionTable';

export default function BankTransactions({ account }: { account: AccountDetail }) {
  const [tab, setTab] = useQueryParam('tab', withDefault(StringParam, 'review'));
  const [importing, setImporting] = useState(false);

  const onImport = (e: ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target;

    if (files) {
      setImporting(true);
      const form = new FormData();
      form.append('account_id', String(account.id));
      form.append('file', files[0]);
      axios.post('/api/bank-transactions/import', form).finally(() => {
        setImporting(false);
      });
    }
  };

  return (
    <div>
      {account.plaid_error && (
        <Box mb={2}>
          <Alert severity="error">{account.plaid_error}</Alert>
        </Box>
      )}

      <Box display="flex">
        <Tabs value={tab} onChange={(e, t) => setTab(t)}>
          <Tab value="review" label="To Review" />
          <Tab value="reviewed" label="Reviewed" />
          <Tab value="excluded" label="Excluded" />
        </Tabs>

        <Box ml="auto">
          <Button component="label">
            Import
            <input type="file" style={{ display: 'none' }} onChange={onImport} />
          </Button>
          <Button component={Link} to="/banking-rules">
            View Rules
          </Button>
        </Box>
      </Box>

      {importing ? (
        <Box py={2}>
          <LinearProgress />
        </Box>
      ) : (
        <BankTransactionTable account={account} mode={tab || 'review'} />
      )}
    </div>
  );
}
