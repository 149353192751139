import { createColumnHelper } from '@tanstack/react-table';
import sumBy from 'lodash/sumBy';
import moment from 'moment';
import { FieldProps } from '@/classes/types';
import PaginatedTable from '@/components/Shared/PaginatedTable';
import TextLink from '@/components/Text/TextLink';
import { Payment } from '@/types';
import curr from '@/utils/curr';
import wrap from '@/utils/wrap';

const columnHelper = createColumnHelper<Payment>();

export default function DepositPayments({ field }: FieldProps) {
  const payments = wrap(field.value) as Payment[];

  return (
    <PaginatedTable
      searchable={['payment_number', 'customer.name']}
      rows={payments}
      columns={[
        columnHelper.accessor('payment_date', {
          header: 'Date',
          cell: ({ getValue }) => moment(getValue()).format('l'),
        }),
        columnHelper.accessor('customer.id', {
          header: 'Customer',
          cell: ({
            row: {
              original: { customer },
            },
          }) =>
            customer ? <TextLink to={`/customers/${customer.id}`}>{customer.name}</TextLink> : '-',
        }),
        columnHelper.accessor('payment_type', {
          header: 'Payment Type',
          cell: ({ row }) => row.original.type_title,
        }),
        columnHelper.accessor('payment_number', {
          header: 'Payment Number',
          cell: ({ getValue, row }) => (
            <TextLink to={`/payments/${row.original.id}`}>{getValue()}</TextLink>
          ),
        }),
        columnHelper.accessor('amount', {
          header: 'Amount',
          cell: ({ getValue }) => curr(getValue()),
          footer: curr(sumBy(payments, 'amount')),
        }),
        columnHelper.accessor('method', {
          header: 'Method',
        }),
      ]}
    />
  );
}
