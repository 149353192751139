import { SyntheticEvent, useCallback, useState } from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import { Alert, Box, Drawer, TextField, Typography } from '@mui/material';
import { Order } from '@stripe/stripe-js';
import { useMutation } from '@tanstack/react-query';
import { createColumnHelper, CellContext } from '@tanstack/react-table';
import axios from 'axios';
import get from 'lodash/get';
import sum from 'lodash/sum';
import { useNavigate } from 'react-router-dom';
import DrawerButtons from '@/components/Form/DrawerButtons';
import PaginatedTable from '@/components/Shared/PaginatedTable';
import { useToast } from '@/contexts/DialogContext';
import { OrderItem } from '@/types';
import curr from '@/utils/curr';
import numString from '@/utils/numString';
import OrderItemProduct from './OrderItemProduct';

const columnHelper = createColumnHelper<OrderItem>();

export default function CreateBackorderDrawer({
  open,
  onClose,
  items,
  orderId,
}: {
  open: boolean;
  onClose: () => void;
  items: OrderItem[];
  orderId: number;
}) {
  const [quantities, setQuantities] = useState<Record<number, number>>({});
  const navigate = useNavigate();
  const toast = useToast();

  const createBackorderRequest = useMutation(
    (payload: { items: { order_item_id: number; qty: number }[] }) =>
      axios.post<Order>(`/api/orders/${orderId}/backorder`, payload).then(({ data }) => data),
  );
  const itemsSum = sum(Object.values(quantities));
  const error = createBackorderRequest.error
    ? get(
        createBackorderRequest.error,
        'response.data.message',
        'Something went wrong. Unable to create backorder',
      )
    : null;

  const onSubmit = (e: SyntheticEvent) => {
    e.preventDefault();
    e.stopPropagation();

    const payload = {
      items: Object.entries(quantities).map(([k, v]) => ({
        order_item_id: Number(k),
        qty: v || 0,
      })),
    };

    createBackorderRequest.mutateAsync(payload).then((order) => {
      onClose();
      navigate(`/orders/${order.id}`);
      toast('The backorder was successfully created');
    });
  };

  const renderQtyInput = useCallback(
    ({ row }: CellContext<OrderItem, any>) => (
      <TextField
        type="number"
        size="small"
        slotProps={{
          htmlInput: {
            max: row.original.qty,
          },
        }}
        onChange={(e) => {
          const value = Number(e.target.value);
          setQuantities((prevState) => ({
            ...prevState,
            [row.original.id]: value,
          }));
        }}
      />
    ),
    [setQuantities],
  );

  return (
    <Drawer anchor="bottom" open={open} onClose={onClose}>
      <Box maxHeight={800} p={3} overflow="auto">
        <Typography>Please specify which items to move to the backorder.</Typography>

        <form onSubmit={onSubmit}>
          <PaginatedTable
            rows={items}
            grouping={['description']}
            columns={[
              columnHelper.accessor('description', {
                header: 'Item',
                cell: ({ row }) => <OrderItemProduct item={row.original} showSize />,
              }),
              columnHelper.accessor('price', {
                header: 'Price',
                cell: ({ getValue }) => curr(getValue(), { maxDecimals: 4 }),
              }),
              columnHelper.accessor('qty', {
                header: 'Qty',
              }),
              columnHelper.accessor('qty_received', {
                header: 'Qty Rec',
              }),
              columnHelper.accessor((row) => row.qty - row.qty_received, {
                id: 'qty_to_receive',
                header: 'Qty To Rec',
              }),
              columnHelper.display({
                id: 'qty_to_backorder',
                header: 'Qty to Backorder',
                cell: renderQtyInput,
              }),
            ]}
          />

          {error && <Alert severity="error">{error}</Alert>}

          <DrawerButtons position="static">
            <LoadingButton
              type="submit"
              variant="contained"
              disabled={itemsSum <= 0}
              loading={createBackorderRequest.isLoading}
            >
              Create Backorder for {numString(itemsSum, 'Items')}
            </LoadingButton>
          </DrawerButtons>
        </form>
      </Box>
    </Drawer>
  );
}
