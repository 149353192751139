import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { Receiving, ReceivingPayload } from '@/types';
import { makeResourceQueryKey, useOnReloadRecord } from '@/utils/genericResource';

type ReceivableType = 'order' | 'purchase_order';

function getQueryKey(receivableType: ReceivableType, receivableId: number) {
  return makeResourceQueryKey(
    receivableType === 'purchase_order' ? 'purchaseOrdersToReceive' : 'ordersToReceive',
    receivableId,
    'receivings',
  );
}

export function useGetReceivings(receivableType: ReceivableType, receivableId: number) {
  return useQuery(getQueryKey(receivableType, receivableId), () =>
    axios
      .get<{ data: Receiving[] }>('/api/receivings', {
        params: {
          [`${receivableType}_id`]: receivableId,
        },
      })
      .then(({ data }) => data.data),
  );
}

export function useCreateReceiving(receivableType: ReceivableType, receivableId: number) {
  const queryClient = useQueryClient();
  const onReload = useOnReloadRecord();

  return useMutation((payload: ReceivingPayload) =>
    axios
      .post<Receiving>(`/api/receivings`, {
        ...payload,
        [`${receivableType}_id`]: receivableId,
      })
      .then(({ data }) => {
        queryClient.setQueryData<Receiving[]>(getQueryKey(receivableType, receivableId), (prev) => {
          if (!prev) {
            return prev;
          }
          return [...prev, data];
        });
        // Reload the PO items to get latest qty_received
        onReload();
        return data;
      }),
  );
}

export function useUpdateReceiving(receivableType: ReceivableType, receivableId: number) {
  const queryClient = useQueryClient();

  return useMutation(({ id, ...payload }: Partial<ReceivingPayload> & { id: number }) =>
    axios.put<Receiving>(`/api/receivings/${id}`, payload).then(({ data }) => {
      queryClient.setQueryData<Receiving[]>(getQueryKey(receivableType, receivableId), (prev) => {
        if (!prev) {
          return prev;
        }
        return prev.map((r) => (r.id === data.id ? data : r));
      });
      return data;
    }),
  );
}

export function useDeleteReceiving(receivableType: ReceivableType, receivableId: number) {
  const queryClient = useQueryClient();
  const onReload = useOnReloadRecord();

  return useMutation((receivingId: number) =>
    axios.delete(`/api/receivings/${receivingId}`).then(() => {
      queryClient.setQueryData<Receiving[]>(getQueryKey(receivableType, receivableId), (prev) => {
        if (!prev) {
          return prev;
        }
        return prev.filter((r) => r.id !== receivingId);
      });
      // Reload the PO items to get latest qty_received
      onReload();
    }),
  );
}

export function useDeleteReceivingItem(receivableType: ReceivableType, receivableId: number) {
  const queryClient = useQueryClient();
  const onReload = useOnReloadRecord();

  return useMutation(({ receivingId, itemId }: { receivingId: number; itemId: number }) => {
    return axios.delete(`/api/receivings/${receivingId}/items/${itemId}`).then(() => {
      queryClient.setQueryData<Receiving[]>(getQueryKey(receivableType, receivableId), (prev) => {
        if (!prev) {
          return prev;
        }
        return prev.map((r) => ({
          ...r,
          items: r.items?.filter((i) => i.id !== itemId),
        }));
      });
      // Reload the PO items to get latest qty_received
      onReload();
    });
  });
}

export function useUnreceivePoItem(receivableType: ReceivableType, receivableId: number) {
  const queryClient = useQueryClient();
  const onReload = useOnReloadRecord();

  return useMutation((poItemId: number) =>
    axios.post(`/api/purchase-order-items/${poItemId}/unreceive`).then(() => {
      queryClient.invalidateQueries<Receiving[]>(getQueryKey(receivableType, receivableId));
      onReload();
    }),
  );
}
