import { useMemo, useState } from 'react';
import {
  Avatar,
  Card,
  CardContent,
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  Grid2 as Grid,
  InputLabel,
  ListItemAvatar,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
} from '@mui/material';
import { createColumnHelper } from '@tanstack/react-table';
import get from 'lodash/get';
import omit from 'lodash/omit';
import pick from 'lodash/pick';
import sumBy from 'lodash/sumBy';
import uniq from 'lodash/uniq';
import uniqBy from 'lodash/uniqBy';
import moment from 'moment';
import { z } from 'zod';
import { useGetDesignLayoutsById } from '@/api/designs';
import {
  useGetKitItemsForPackout,
  useKitProductionOrders,
  usePickKitItem,
  useWatchKitItems,
} from '@/api/kitting';
import { FieldFactory } from '@/classes';
import KitLabel from '@/components/Kitting/KitLabel';
import OrderItemProduct from '@/components/Orders/Items/OrderItemProduct';
import OrderLabel from '@/components/Orders/OrderLabel';
import PaginatedTable from '@/components/Shared/PaginatedTable';
import { useDialogs } from '@/contexts/DialogContext';
import useScanDetection from '@/hooks/useScanDetection';
import { useSkipper } from '@/hooks/useSkipper';
import { KitItem } from '@/types';
import { useRecord } from '@/utils/genericResource';
import { buildIncrementId } from '@/utils/notes';
import truthy from '@/utils/truthy';

const LABELS = {
  order_id: 'Order ID',
  number: 'Style Number',
  color: 'Color',
  size: 'Size',
  design_layout_id: 'Design Layout',
  upc: 'UPC',
  'variant.gtin': 'GTIN',
  id: 'Order Item',
  description: 'Description',
  'vendor.name': 'Vendor',
  drops: 'Name/Number',
  bin_sequence: 'Bin Sequence',
} as const;

function dropsToString(drops: string[] | null) {
  return drops?.join(' / ') || '-';
}

const columnHelper = createColumnHelper<KitItem>();

export default function PackoutStartedStatus() {
  const { order } = useRecord('orderPackouts');
  const orderId = order.id;

  const [autoResetPageIndex, skipResetPageIndex] = useSkipper();
  const { data = [], isFetching, refetch } = useGetKitItemsForPackout(orderId);
  useWatchKitItems(orderId, skipResetPageIndex);
  const { mutate, isLoading } = usePickKitItem(orderId, skipResetPageIndex);
  const { data: designLayouts = [] } = useGetDesignLayoutsById(
    uniq(truthy(data.map((item) => item.order_item.design_layout_id))),
  );
  const { data: orders = [] } = useKitProductionOrders(orderId);
  const orderItems = useMemo(
    () =>
      uniqBy(
        data.map((item) => item.order_item),
        'id',
      ),
    [data],
  );

  const someDrops = data.some((item) => item.drops && item.drops.length > 0);
  const [filters, setFilters] = useState<Partial<Record<keyof typeof LABELS, any>>>({});
  const [hidePicked, setHidePicked] = useState(false);
  const { prompt } = useDialogs();

  useScanDetection({
    onComplete: (scan) => {
      if (/^[0-9]{12}$/.test(scan)) {
        setFilters((prev) => ({
          ...pick(prev, ['order_id', 'design_layout_id']),
          upc: scan,
        }));
      } else if (/^[0-9]{14}$/.test(scan)) {
        setFilters((prev) => ({
          ...pick(prev, ['order_id', 'design_layout_id']),
          'variant.gtin': scan,
        }));
      } else if (/^[0-9]{5,11}$/.test(scan)) {
        setFilters({ id: Number(scan) });
      }
    },
  });

  const filteredData = useMemo(() => {
    let newData = data;

    if (hidePicked) {
      const recent = moment().subtract(7, 'seconds');

      newData = newData.filter(
        (ki) => ki.picked_qty < ki.qty || (ki.picked_at && moment(ki.picked_at).isAfter(recent)),
      );
    }

    return newData.filter((item) => {
      return Object.entries(filters).every(([key, value]) => {
        if (!value) return true;
        if (key === 'bin_sequence') {
          return item.kit.bin_string?.[0] === value;
        }
        if (key === 'drops') {
          return dropsToString(item.drops) === value;
        }
        if (key === 'description') {
          return get(item.order_item, key, '').toLowerCase().includes(String(value).toLowerCase());
        }
        return get(item.order_item, key) == value;
      });
    });
  }, [data, filters, hidePicked]);

  const handleCheckboxChange = (item: KitItem) => async () => {
    if (item.picked_qty < item.qty) {
      let qty = item.qty;
      if (item.qty > 1) {
        qty = await prompt({
          title: 'Enter Quantity',
          description: `This item has a quantity of ${item.qty}. Please enter the quantity you would like to pick:`,
          fields: [
            FieldFactory.number('qty')
              .withSize('medium')
              .withHelp('Defaults to current picked quantity plus one')
              .withInputProps({
                inputProps: { min: 1, max: item.qty, step: 1 },
              }),
          ],
          initialValues: { qty: item.picked_qty + 1 },
          schema: z.object({ qty: z.coerce.number().int().positive().max(item.qty) }),
        }).then((result) => result.qty);
      }

      if (qty) {
        mutate({ id: item.id, picked_qty: qty });
      }
    } else {
      mutate({ id: item.id, picked_qty: 0 });
    }
  };

  const getOptionsForKey = (key: keyof typeof LABELS) => {
    return Array.from(
      new Set(
        filteredData.map((item) => {
          if (key === 'bin_sequence') {
            return item.kit.bin_string?.[0];
          }
          if (key === 'drops') {
            return dropsToString(item.drops);
          }
          return get(item.order_item, key);
        }),
      ),
    ).sort();
  };

  return (
    <Stack spacing={2}>
      <Card>
        <CardContent>
          <Grid container spacing={2}>
            <Grid size={12} sx={{ display: 'flex', gap: 1 }}>
              {Object.entries(filters).map(([key, value]) => (
                <Chip
                  key={key}
                  label={
                    <>
                      {LABELS[key as keyof typeof LABELS]}: <b>{value}</b> &times;
                    </>
                  }
                  clickable
                  onClick={() => {
                    setFilters((prev) => omit(prev, key));
                  }}
                />
              ))}
            </Grid>
            {Object.keys(LABELS).map((k) => {
              const key = k as keyof typeof LABELS;
              const label = LABELS[key];
              const options = getOptionsForKey(key);

              if (options.length <= 1) {
                return null;
              }

              if (key === 'upc' || key === 'variant.gtin') {
                // Scan Only
                return null;
              }

              return (
                <Grid size={{ xs: 6, lg: 4 }} key={key}>
                  <FormControl fullWidth size="small">
                    <InputLabel>{label}</InputLabel>

                    {key === 'description' ? (
                      <OutlinedInput
                        label={label}
                        defaultValue={filters[key]}
                        type="search"
                        onKeyUp={(e) => {
                          if (e.key === 'Enter') {
                            setFilters({ ...filters, [key]: e.currentTarget.value });
                          }
                        }}
                        onBlur={(e) => {
                          if (e.target.value) {
                            setFilters({ ...filters, [key]: e.target.value });
                          }
                        }}
                      />
                    ) : (
                      <Select
                        label={label}
                        value={filters[key] || ''}
                        onChange={(e) => setFilters({ ...filters, [key]: e.target.value })}
                        variant="outlined"
                        sx={{
                          '& .MuiSelect-select': { display: 'flex', alignItems: 'center' },
                        }}
                      >
                        {getOptionsForKey(key).map((value) => {
                          const designLayout =
                            key === 'design_layout_id' && designLayouts.find((l) => l.id == value);
                          const order = key === 'order_id' && orders.find((l) => l.id == value);
                          const orderItem = key === 'id' && orderItems.find((l) => l.id == value);

                          return (
                            // @ts-expect-error this is fine
                            <MenuItem key={value} value={value}>
                              {order ? (
                                <ListItemText
                                  primary={order.increment_id}
                                  secondary={order.description}
                                />
                              ) : designLayout ? (
                                <>
                                  {designLayout.image && (
                                    <ListItemAvatar>
                                      <Avatar src={designLayout.image} />
                                    </ListItemAvatar>
                                  )}
                                  <ListItemText
                                    primary={designLayout.increment_id}
                                    secondary={designLayout.name}
                                  />
                                </>
                              ) : orderItem ? (
                                <OrderItemProduct item={orderItem} showSize />
                              ) : (
                                <ListItemText primary={value} />
                              )}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    )}
                  </FormControl>
                </Grid>
              );
            })}
            <Grid size={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={hidePicked}
                    onChange={(e) => setHidePicked(e.target.checked)}
                  />
                }
                label="Hide Picked"
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      <Card>
        <PaginatedTable
          rows={filteredData}
          filename={`packout-${order.increment_id.toLowerCase()}`}
          grouping={['order_item_description']}
          isFetching={isFetching}
          refetch={refetch}
          autoResetPageIndex={autoResetPageIndex}
          columns={[
            columnHelper.display({
              id: 'checkbox',
              header: '',
              enableHiding: false,
              cell: ({ row: { original: item } }) => (
                <Checkbox
                  disabled={isLoading}
                  checked={item.picked_qty === item.qty}
                  indeterminate={item.picked_qty > 0 && item.picked_qty < item.qty}
                  onChange={handleCheckboxChange(item)}
                />
              ),
            }),
            columnHelper.accessor('kit.bin_string', {
              header: 'Bin',
              enableHiding: false,
              cell: ({ row }) => <KitLabel kit={row.original.kit!} />,
            }),
            columnHelper.accessor('qty', {
              header: 'Qty',
              enableHiding: false,
              aggregationFn: 'sum',
              footer: sumBy(filteredData, 'qty').toString(),
            }),
            columnHelper.accessor('picked_at', {
              header: 'Picked At',
            }),
            columnHelper.accessor('drops', {
              header: 'Name/Number',
              enableHiding: false,
              cell: ({ getValue }) => dropsToString(getValue()),
            }),
            columnHelper.accessor('order_item.description', {
              header: 'Item',
              cell: ({ row }) => <OrderItemProduct item={row.original.order_item} />,
            }),
            columnHelper.accessor('order_item.number', {
              header: 'Style Number',
            }),
            columnHelper.accessor('order_item.color', {
              header: 'Color',
            }),
            columnHelper.accessor('order_item.size', {
              header: 'Size',
            }),
            columnHelper.accessor('order_item.design_layout_id', {
              header: 'Design Layout',
              aggregatedCell: ({ getValue }) => buildIncrementId('LAY', getValue()),
              cell: ({ getValue }) => buildIncrementId('LAY', getValue()),
            }),
            columnHelper.accessor('order_item.order_id', {
              header: 'Order ID',
              aggregatedCell: ({ getValue }) => buildIncrementId('SO', getValue()),
              cell: ({ getValue }) => <OrderLabel orderId={getValue()} />,
            }),
            columnHelper.accessor('order_item.variant.product.color_code', {
              header: 'Color Code',
            }),
            columnHelper.accessor('order_item.upc', {
              header: 'UPC',
            }),
            columnHelper.accessor('order_item.id', {
              header: 'Order Item ID',
            }),
            columnHelper.accessor('kit.name', {
              header: 'Kit Name',
            }),
            columnHelper.accessor('kit.number', {
              header: 'Kit Number',
            }),
            columnHelper.accessor('kit.group', {
              header: 'Kit Group',
            }),
            columnHelper.accessor('kit.email', {
              header: 'Kit Email',
            }),
          ]}
          columnVisibility={{
            checkbox: true,
            drops: someDrops,
            picked_at: false,
            order_item_number: false,
            order_item_color: false,
            order_item_variant_product_color_code: false,
            order_item_upc: false,
            order_item_id: false,
            order_item_order_id: false,
            kit_name: false,
            kit_number: false,
            kit_group: false,
            kit_email: false,
          }}
        />
      </Card>
    </Stack>
  );
}
