import {
  Account,
  ArtRequest,
  ArtRequestActivity,
  BillMatchStatus,
  BillPaidStatus,
  CompareTo,
  Design,
  FulfillmentOrder,
  InventoryLocation,
  InventoryPick,
  InventoryReturn,
  Order,
  OrderApprovalStatus,
  OrderDepartmentStatus,
  OrderItem,
  Packout,
  Payment,
  PickReadyStatus,
  PurchaseOrder,
  Quote,
  ReceivablesBreakdown,
  Report,
  ReportFilterType,
} from '@/types';

export const EMPTY_ARRAY = [];

export const returnFalse = () => false;

export const GOOGLE_MAPS_KEY = 'AIzaSyClWFn4TGhUzZ5PXgLgU2Ezvnx10H8TNYg';

export const ACCOUNT_TYPES: Record<Account['type'], string> = {
  asset: 'Asset',
  bank: 'Bank',
  long_term_asset: 'Long Term Asset',
  other_asset: 'Other Asset',
  other_current_asset: 'Other Current Asset',
  liability: 'Liability',
  credit_card: 'Credit Card',
  tax: 'Tax Liability',
  long_term_liability: 'Long Term Liability',
  other_current_liability: 'Other Current Liability',
  equity: 'Equity',
  revenue: 'Revenue',
  expense: 'Expense',
  cogs: 'Cost of Goods',
};

export const ART_REQUEST_ACTIVITY_TYPES: Record<ArtRequestActivity['type'], string> = {
  sew_out: 'Sew Out',
  vectorize: 'Vectorize',
  digitize: 'Digitize',
};

export const ART_REQUEST_STATUS_COLORS: Record<ArtRequest['status'], string> = {
  completed: 'green',
  rejected: 'red',
  pending: 'secondary',
  pending_activity: 'amber',
};

export const ART_REQUEST_TYPES: Record<ArtRequest['type'], string> = {
  proof: 'Proof',
  release: 'Release',
};

export const PORTAL_SHOPPING_MODES = {
  blank: 'Blank Product Only',
  decorated: 'Decorated Product Only',
  both: 'Blank & Decorated Product',
  stortal: 'Stortal',
};

export const REPORT_TYPES: Record<Report['type'], string> = {
  table: 'Table',
  number: 'Big Number',
  list: 'List',
  line: 'Line Graph',
  bar: 'Bar Graph',
  area: 'Area Graph',
  progress: 'Circular Progress Bar',
};

export const DESIGN_STATUS_COLORS: Record<Design['status'], string> = {
  Complete: 'green',
  Incomplete: 'amber',
};

export const PICK_STATUS_COLORS: Record<InventoryPick['status'], string> = {
  complete: 'green',
  picking: 'blue',
  issued: 'blueGrey',
  cancellation_requested: 'red',
  on_hold: 'brown',
  pending_shipment: 'purple',
};

export const RETURN_STATUS_COLORS: Record<InventoryReturn['status'], string> = {
  completed: 'green',
  processing: 'blue',
  awaiting: 'blueGrey',
  cancelled: 'red',
};

export const PICK_READY_COLORS: Record<PickReadyStatus, string> = {
  ready: 'success',
  some_not_pickable: 'warning',
  not_pickable: 'warning',
  some_unavailable: 'warning',
  unavailable: 'error',
};

export const PACKOUT_STATUS_COLOR: Record<Packout['status'], string> = {
  pending: 'gray',
  started: 'amber',
  shipping: 'blue',
  completed: 'green',
};

export const PACKOUT_READY_STATUS_COLOR = {
  blocked: 'red',
  ready: 'green',
  misconfigured: 'purple',
  unapproved: 'pink',
} as const;

export const ORDER_STATUS_COLORS = {
  Paid: 'green',
  'Partially Paid': 'amber',
  Invoiced: 'lightGreen',
  Complete: 'lime',
  'Pending Webstore Import': 'teal',
  'Waiting on Packout': 'teal',
  'Ready to Ship': 'teal',
  'In Production': 'cyan',
  'Ready to Produce': 'blue',
  'Waiting on Product': 'deepOrange',
  'To Be Purchased': 'amber',
  'To Be Released': 'deepPurple',
  'Pending Production Approval': 'purple',
  'Release Requested': 'purple',
  'Proof Requested': 'purple',
  'Approval Sent': 'pink',
  'Waiting on Customer Payment': 'pink',
  'Waiting on Customer PO': 'pink',
  Unapproved: 'blueGrey',
  Cancelled: 'red',
  'Has Issue': 'red',
};

export const ORDER_STATUS_DETAILS: Record<string, string> = {
  Paid: 'This order is complete and paid.',
  'Partially Paid': 'This order does not have a balance of 0.',
  Invoiced: 'This order is complete but is awaiting payment.',
  'Release Requested': 'Waiting on Art to release this order to production.',
  'Proof Requested': 'Waiting on Art to create a proof for this order.',
  Unapproved:
    'Next Step: Request a proof, send out an approval, or manually approve the order yourself.',
  'To Be Released': 'Next Step: Send a request to Art to release this order to production.',
  Complete: 'This order is complete. Next Step: Invoice this order.',
  'Ready to Ship': 'This order has been completed and is awaiting shipment.',
  'In Production': 'Production has been started on this order.',
  'Ready to Produce': 'All items are received and ready to be produced.',
  'Waiting on Product': 'Items have been purchased, but waiting for all items to be received.',
  'To Be Purchased': 'Order has been approved, but is awaiting to be purchased.',
  'Approval Sent':
    'Proof and Approval has been sent to the customer, but it is awaiting a response',
  'Has Issue': 'An issue needs to be resolved',
};

export const PAYMENT_METHODS = {
  card: 'Credit Card',
  check: 'Check',
  cash: 'Cash',
  ach: 'ACH',
  card_present: 'Card Present',
  other: 'Other',
};

export const PAYMENT_TYPES: Record<Payment['payment_type'], string> = {
  payment: 'Payment',
  memo: 'Credit/Debit Memo',
  deduction: 'Payout Deduction',
  bad_debt: 'Bad Debt',
};

export const APPLIED_STATUS_LABELS: Record<Payment['applied_status'], string> = {
  applied: 'Applied',
  partial: 'Partially Applied',
  unapplied: 'Unapplied',
};

export const APPLIED_STATUS_COLORS: Record<Payment['applied_status'], string> = {
  applied: 'green',
  partial: 'amber',
  unapplied: 'red',
};

export const PRODUCTION_EVENT_STATUS_COLORS: Record<string, string> = {
  Completed: 'green',
  Packing: 'purple',
  'To Be Packed': 'indigo',
  Started: 'blue',
  'Ready to Start': 'cyan',
  'To Be Approved': 'orange',
  'Setting Up': 'amber',
  Scheduled: 'pink',
  Unscheduled: 'blueGrey',
};

export const PURCHASE_ORDER_STATUS_COLORS: Record<PurchaseOrder['status'], string> = {
  Received: 'green',
  'Partially Received': 'amber',
  Submitted: 'lightGreen',
  Unsubmitted: 'gray',
};

export const QUOTE_STATUS_COLORS: Record<Quote['status'], string> = {
  open: 'blue',
  declined: 'red',
  accepted: 'green',
  ordered: 'lightGreen',
};

export const TRANSACTION_TYPES = {
  expense: 'Expense',
  check: 'Check',
  bill: 'Bill',
  bill_payment: 'Bill Payment',
  transfer: 'Bank Transfer',
} as const;

export const TRANSACTION_PAID_COLORS: Record<BillPaidStatus, string> = {
  paid: 'green',
  partial: 'amber',
  unpaid: 'orange',
  on_hold: 'red',
};

export const TRANSACTION_PAID_LABELS: Record<BillPaidStatus, string> = {
  paid: 'Paid',
  partial: 'Partially Paid',
  unpaid: 'Unpaid',
  on_hold: 'On Hold',
};

export const VENDOR_PAYMENT_METHODS = {
  check: 'Check',
  ach: 'ACH',
  credit_card: 'Credit Card',
  web_eft: 'Website EFT',
  web_credit_card: 'Website Credit Card',
  call_credit_card: 'Call In Credit Card',
  autopay_credit_card: 'Autopay Credit Card',
  autopay_eft: 'Autopay EFT',
} as const;

export const ISSUE_STATUS_COLORS = {
  open: 'red',
  in_process: 'amber',
  to_process: 'purple',
  resolved: 'green',
} as const;

export const COMMISSION_HIT_OPTIONS = [
  {
    value: 1,
    label: "Deduct user's commission % of amount",
  },
  {
    value: 2,
    label: 'Deduct 100% of amount',
  },
  {
    value: 3,
    label: 'Deduct 100% of amount without making commission < 0',
  },
];

export const REPORT_FILTER_TYPES: ReportFilterType[] = [
  'text',
  'belongsTo',
  'hasMany',
  'daterange',
  'select',
  'boolean',
];

export const INVENTORY_LOCATION_TYPES: Record<InventoryLocation['type'], string> = {
  bin: 'Bin',
  overstock: 'Overstock',
  pallet: 'Pallet',
  temporary: 'Temporary',
  multi_bin: 'Multi-SKU Bin',
  pickable_pallet: 'Pallet (Pickable)',
};

export const PARTY_COLORS: Record<OrderItem['ship_to_party'], string> = {
  avail: 'primary',
  subcontractor: 'orange',
  customer: 'red',
};

export const WEBSTORE_ORDER_STATUS_COLORS: Record<FulfillmentOrder['status'], string> = {
  pending: 'gray',
  waiting: 'pink',
  issued: 'blue',
  processing: 'amber',
  shipped: 'green',
  voided: 'brown',
  backordered: 'red',
};

export const SHIPPING_MODE_OPTIONS: {
  value: Order['shipping_mode'];
  label: string;
  requiresPermission?: string;
}[] = [
  { value: 'bulk', label: 'Single Address (Default)' },
  { value: 'kits', label: 'Kit & Ship to Multiple Addresses' },
  {
    value: 'inventory',
    label: 'Add to Inventory',
    requiresPermission: 'orders:inventory',
  },
  {
    value: 'inventory_kit',
    label: 'Add to Inventory as a Kit',
    requiresPermission: 'orders:inventory',
  },
];

export const MATCH_STATUS_COLORS: Record<BillMatchStatus, string> = {
  no_purchase_order: 'amber',
  matched: 'green',
  waiting_on_invoice: 'purple',
  waiting_on_credit: 'purple',
  to_review: 'red',
};

export const MATCH_STATUS_LABELS: Record<BillMatchStatus, string> = {
  no_purchase_order: 'No PO Linked',
  matched: 'Matched',
  waiting_on_invoice: 'Waiting on Invoice',
  waiting_on_credit: 'Waiting on Credit',
  to_review: 'To Review',
};

export const DEPARTMENT_STATUS_COLORS: Record<OrderDepartmentStatus, string> = {
  none: 'gray',
  no: 'gray',
  partial: 'amber',
  yes: 'green',
  issue: 'red',
};

export const APPROVAL_STATUS_COLORS: Record<OrderApprovalStatus, string> = {
  none: 'gray',
  no: 'gray',
  proof_requested: 'purple',
  approval_sent: 'pink',
  yes: 'green',
};

export const COMPARE_TO_LABELS: Record<CompareTo, string> = {
  last_year: 'same period last year',
  prev_period: 'previous period',
};

export const RECEIVABLE_BREAKDOWN_KEYS: (keyof ReceivablesBreakdown)[] = [
  'current',
  '1_30',
  '31_60',
  '61_90',
  '91_',
  'total',
];

export const RECEIVABLE_BREAKDOWN_LABELS: Record<keyof ReceivablesBreakdown, string> = {
  current: 'Current',
  '1_30': '1-30 Days',
  '31_60': '31-60 Days',
  '61_90': '61-90 Days',
  '91_': '91+ Days',
  total: 'Total',
};

export const ITEM_CLASSIFICATIONS = {
  product: 'Product',
  service: 'Service',
  apparel: 'Apparel',
  shoes: 'Shoes',
  hats: 'Hats',
  shipping: 'Shipping',
  decoration: 'Decoration',
  merchant_fees: 'Merchant Fees',
  fulfillment_storage: 'Fulfillment Storage',
  fulfillment_picks: 'Fulfillment Picks',
  other: 'Other',
} as const;

export const PO_STATUS_IDS: Record<number, string> = {
  20: 'Order Received',
  40: 'On Hold',
  60: 'In Production',
  75: 'Partially Shipped',
  80: 'Shipped',
  99: 'Cancelled',
};

export const PO_STATUS_ID_COLORS: Record<number, string> = {
  20: 'gray',
  40: 'brown',
  60: 'cyan',
  75: 'amber',
  80: 'green',
  99: 'red',
};

export const FULFILLMENT_FEES = [
  'STORAGE_BASE',
  'STORAGE_BIN',
  'STORAGE_PALLET',
  'PICK_PACK',
  'PICK_PACK_PLUS',
  'FULFILL_SHIPPING',
  'PROCESSING_FEES',
  'RETURN',
  'RETURN_SHIPPING',
];

export const PROJECT_STATUS_COLORS = {
  draft: 'grey',
  options_requested: 'indigo',
  options_provided: 'blue',
  proofs_requested: 'amber',
  ordered: 'teal',
  complete: 'green',
  cancelled: 'red',
};
