import { ReactNode, useMemo, useState } from 'react';
import { FileDownload, Refresh } from '@mui/icons-material';
import {
  Box,
  CircularProgress,
  FormControlLabel,
  IconButton,
  Switch,
  Tooltip,
} from '@mui/material';
import {
  ColumnDef,
  ColumnSort,
  getCoreRowModel,
  getExpandedRowModel,
  getGroupedRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  GroupingState,
  Row,
  useReactTable,
} from '@tanstack/react-table';
import { download, generateCsv, mkConfig } from 'export-to-csv';
import ColumnsDialog from '@/components/DataTable/ColumnsDialog';
import ReactTable from '@/components/Shared/ReactTable';
import { returnFalse } from '@/constants';
import searchCollection from '@/utils/searchCollection';
import DebouncedTextField from './DebouncedTextField';

export default function PaginatedTable<T extends Record<string, any>>({
  rows,
  columns,
  searchable,
  grouping: groupingProp,
  initialIsGrouped = false,
  initialRowsPerPage = 25,
  initialSortBy,
  getRowCanExpand,
  size,
  columnVisibility,
  filename,
  enableToolbar,
  toolbarActions,
  refetch,
  isFetching,
  autoResetPageIndex = true,
}: {
  rows: T[];
  columns: ColumnDef<T, any>[];
  searchable?: string[];
  grouping?: GroupingState;
  initialIsGrouped?: boolean;
  initialRowsPerPage?: number;
  initialSortBy?: ColumnSort;
  getRowCanExpand?: (row: Row<T>) => boolean;
  size?: 'small' | 'medium';
  columnVisibility?: Record<string, boolean>;
  filename?: string;
  enableToolbar?: boolean;
  toolbarActions?: ReactNode;
  refetch?: () => void;
  isFetching?: boolean;
  autoResetPageIndex?: boolean;
}) {
  const [query, setQuery] = useState('');
  const [isGrouped, setIsGrouped] = useState(initialIsGrouped);

  const filteredItems = useMemo(() => {
    return searchable ? searchCollection(rows, query, searchable) : rows;
  }, [rows, query, searchable?.join('|')]);

  const stableColumns = useMemo(
    () =>
      columns.map((c) => ({
        aggregationFn: 'same',
        ...c,
      })) as ColumnDef<T, any>[],
    [columns],
  );

  const grouping = useMemo(
    () => (isGrouped ? groupingProp : []),
    [isGrouped, groupingProp?.join('|')],
  );

  const table = useReactTable({
    data: filteredItems,
    columns: stableColumns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getGroupedRowModel: getGroupedRowModel(),
    groupedColumnMode: false,
    aggregationFns: {
      same: (columnId, leafRows) => {
        if (leafRows.every((r) => r.getValue(columnId) === leafRows[0].getValue(columnId))) {
          return leafRows[0].getValue(columnId);
        }
        return null;
      },
    },
    autoResetExpanded: false,
    getRowCanExpand: getRowCanExpand || returnFalse,
    autoResetPageIndex,
    state: {
      grouping,
    },
    initialState: {
      columnVisibility,
      expanded: getRowCanExpand ? {} : true,
      sorting: initialSortBy ? [initialSortBy] : [],
      pagination: {
        pageSize: initialRowsPerPage,
      },
    },
  });

  const csvConfig = mkConfig({
    fieldSeparator: ',',
    filename, // export file name (without .csv)
    decimalSeparator: '.',
    useKeysAsHeaders: true,
  });

  const shouldRenderToolbar = Boolean(
    enableToolbar != null
      ? enableToolbar
      : refetch || isFetching != null || filename || grouping || searchable || toolbarActions,
  );

  return (
    <>
      {shouldRenderToolbar && (
        <Box
          sx={{
            px: 1,
            py: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            gap: 2,
          }}
        >
          <Box display="flex" alignItems="center">
            {isFetching ? (
              <Box p={1.5} display="inline-flex">
                <CircularProgress size={24} />
              </Box>
            ) : refetch ? (
              <Tooltip title="Reload" placement="top">
                <IconButton onClick={refetch} size="large">
                  <Refresh />
                </IconButton>
              </Tooltip>
            ) : null}

            <ColumnsDialog
              allColumns={table
                .getAllColumns()
                .filter((c) => c.getCanHide())
                .map((c) => ({
                  name: c.id,
                  label: c.columnDef.header as string,
                }))}
              toggleColumn={(column) => {
                table
                  .getAllColumns()
                  .find((c) => c.id === column)
                  ?.toggleVisibility();
              }}
              columns={table
                .getAllColumns()
                .filter((c) => c.getIsVisible())
                .map((c) => c.id)}
            />
            {filename && (
              <Tooltip title="Export to CSV" placement="top">
                <IconButton
                  onClick={() => {
                    const rowData = table.getFilteredRowModel().rows.map((row) => {
                      const columns: Record<string, any> = {};
                      row.getAllCells().forEach(({ column, getValue }) => {
                        if (!('accessorKey' in column.columnDef)) {
                          return;
                        }
                        const value = getValue();
                        columns[
                          typeof column.columnDef.header === 'string'
                            ? column.columnDef.header
                            : column.id
                        ] = value == null ? '' : getValue();
                      });
                      return columns;
                    });
                    const csv = generateCsv(csvConfig)(rowData);
                    download(csvConfig)(csv);
                  }}
                  size="large"
                >
                  <FileDownload />
                </IconButton>
              </Tooltip>
            )}
          </Box>

          <Box display="flex" alignItems="center" gap={1}>
            {groupingProp && (
              <FormControlLabel
                control={
                  <Switch checked={isGrouped} onChange={(e) => setIsGrouped(e.target.checked)} />
                }
                label="Grouped"
              />
            )}

            {searchable && (
              <DebouncedTextField
                initialValue={query}
                onChange={(value: string) => {
                  setQuery(value);
                  table.setPageIndex(0);
                }}
                size="small"
                type="search"
                label="Search"
                fullWidth
                style={{ maxWidth: 300 }}
              />
            )}

            {toolbarActions}
          </Box>
        </Box>
      )}

      <ReactTable table={table} size={size} isFetching={isFetching} />
    </>
  );
}
