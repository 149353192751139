import { ChangeEvent, useEffect, useState } from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Card,
  CardActions,
  Checkbox,
  CircularProgress,
  FormControl,
  Grid2 as Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from '@mui/material';
import { useMutation, useQuery } from '@tanstack/react-query';
import { createColumnHelper } from '@tanstack/react-table';
import axios from 'axios';
import map from 'lodash/map';
import toString from 'lodash/toString';
import without from 'lodash/without';
import { useSessionStorage } from 'usehooks-ts';
import { useGetBankAccounts } from '@/api/accounts';
import PaginatedTable from '@/components/Shared/PaginatedTable';
import Paper from '@/components/Shared/Paper';
import TextLink from '@/components/Text/TextLink';
import { useTitle } from '@/contexts/AppContext';
import { useDialogs } from '@/contexts/DialogContext';
import { Transaction } from '@/types';
import curr from '@/utils/curr';
import { formatDate } from '@/utils/dates';
import numString from '@/utils/numString';

const columnHelper = createColumnHelper<Transaction>();
export default function PrintChecks() {
  const [type, setType] = useState('check');
  const [lastPrinted, setLastPrinted] = useState<number>();
  const [bankAccountId, setBankAccountId] = useSessionStorage<number | undefined>(
    'checksToPrintBankAccountId',
    undefined,
  );
  const [checkNumber, setCheckNumber] = useState<string>();
  const [selected, setSelected] = useState<number[]>([]);
  const { confirm } = useDialogs();

  useTitle('Print Checks', '/transactions');

  const { data: transactions } = useQuery(
    ['checksToPrint', bankAccountId, lastPrinted],
    () => {
      const params = {
        to_print: type,
        'filter[bank_account_id]': bankAccountId,
        count: 250,
      };
      return axios
        .get<{ data: Transaction[] }>('/api/transactions', { params })
        .then(({ data }) => data.data);
    },
    {
      onSuccess: (rows) => {
        setSelected(rows.map((r) => r.id));
      },
    },
  );
  const { data: bankAccounts = [] } = useGetBankAccounts();
  const bankAccount = bankAccounts.find((a) => a.id === bankAccountId);

  useEffect(() => {
    if (bankAccount && bankAccount.next_check_number) {
      setCheckNumber(String(bankAccount.next_check_number));
    }
  }, [bankAccount?.id, lastPrinted]);

  const onToggleAll = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      setSelected(map(transactions, 'id'));
    } else {
      setSelected([]);
    }
  };

  const onToggle = (id: number) => (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      setSelected((prev) => [...prev, id]);
    } else {
      setSelected((prev) => without(prev, id));
    }
  };

  const onPrint = useMutation(() => {
    const payload = {
      bank_account_id: bankAccountId,
      starting_check_number: checkNumber,
      transaction_ids: selected,
      type,
    };

    return axios.post('/api/transactions/print-checks', payload).then(({ data }) => {
      if (data.url) {
        window.open(data.url, '_blank');

        confirm({
          title: 'Confirm Checks Printed',
          description:
            'Did the checks successfully print? This will remove them from the list of checks to print.',
        }).then(() => {
          axios.post('/api/transactions/mark-checks', payload).then(() => {
            setLastPrinted(Date.now());
          });
        });
      }
    });
  });

  if (!transactions) {
    return <CircularProgress />;
  }

  return (
    <Stack spacing={2}>
      <Paper>
        <Grid container spacing={3}>
          <Grid size={{ xs: 12, md: 4, lg: 3 }}>
            <FormControl variant="outlined" sx={{ mr: 2 }} fullWidth>
              <InputLabel id="bank-account-label">Type</InputLabel>
              <Select
                labelId="type-label"
                value={type}
                onChange={(e) => setType(e.target.value)}
                label="Type"
              >
                <MenuItem value="check">Check</MenuItem>
                <MenuItem value="ach">ACH</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid size={{ xs: 12, md: 4, lg: 3 }}>
            <FormControl variant="outlined" sx={{ mr: 2 }} fullWidth>
              <InputLabel id="bank-account-label">Bank Account</InputLabel>
              <Select
                labelId="bank-account-label"
                value={bankAccountId}
                onChange={(e) => setBankAccountId(Number(e.target.value))}
                label="Bank Account"
              >
                {bankAccounts.map((a) => (
                  <MenuItem value={a.id} key={a.id}>
                    {a.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          {type === 'check' && (
            <Grid size={{ xs: 12, md: 4, lg: 3 }}>
              <TextField
                fullWidth
                label="Starting Check #"
                variant="outlined"
                onChange={(e) => setCheckNumber(e.target.value)}
                value={toString(checkNumber)}
              />
            </Grid>
          )}
        </Grid>
      </Paper>
      <Card>
        <PaginatedTable
          rows={transactions}
          columns={[
            columnHelper.display({
              id: 'checkbox',
              header: () => (
                <Checkbox
                  checked={selected.length > 0 && transactions.length === selected.length}
                  indeterminate={selected.length > 0 && selected.length < transactions.length}
                  onChange={onToggleAll}
                />
              ),
              cell: ({ row }) => (
                <Checkbox
                  checked={selected.includes(row.original.id)}
                  onChange={onToggle(row.original.id)}
                />
              ),
            }),
            columnHelper.accessor('transaction_date', {
              header: 'Date',
              cell: ({ getValue }) => formatDate(getValue()),
            }),
            columnHelper.accessor('label', {
              header: 'Transaction',
              cell: ({ row, getValue }) => (
                <TextLink to={`/transactions/${row.original.id}`}>{getValue()}</TextLink>
              ),
            }),
            columnHelper.accessor('vendor.name', {
              header: 'Vendor',
            }),
            columnHelper.accessor('total', {
              header: 'Amount',
              cell: ({ getValue }) => curr(getValue()),
            }),
          ]}
        />
        <CardActions>
          <LoadingButton
            variant="contained"
            disabled={selected.length === 0}
            onClick={() => onPrint.mutate()}
            loading={onPrint.isLoading}
          >
            {type === 'ach'
              ? `NACHA File for ${numString(selected.length, 'Transactions')}`
              : `Print ${numString(selected.length, 'Checks')}`}
          </LoadingButton>
        </CardActions>
      </Card>
    </Stack>
  );
}
