import { styled } from '@mui/material';
import { OrderItem } from '@/types';

const QtyTable = styled('table')(({ theme }) => ({
  lineHeight: 1.2,
  margin: `0 ${theme.spacing(-2)}`,
  fontSize: '.8rem',
  borderSpacing: 0,
  color: theme.palette.action.active,
}));

export default function OrderItemQtys({ item }: { item: OrderItem }) {
  if (item.qty_sourced === 0 && item.qty_received === 0) {
    return null;
  }
  return (
    <QtyTable>
      {item.qty_sourced !== 0 && (
        <tr>
          <td>Pur:</td>
          <td>{item.qty_sourced}</td>
        </tr>
      )}
      {item.qty_received !== 0 && (
        <tr>
          <td>Rec:</td>
          <td>{item.qty_received}</td>
        </tr>
      )}
      {item.qty_shipped !== 0 && (
        <tr>
          <td>Shp:</td>
          <td>{item.qty_shipped}</td>
        </tr>
      )}
    </QtyTable>
  );
}
