import { useState } from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { CompareArrows, DragHandle, PinDrop } from '@mui/icons-material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import DuplicateIcon from '@mui/icons-material/FileCopy';
import DesignIcon from '@mui/icons-material/Image';
import ListIcon from '@mui/icons-material/List';
import PurchaseIcon from '@mui/icons-material/LocalOffer';
import ShipIcon from '@mui/icons-material/LocalShipping';
import { IconButton, styled, TableCell, TableRow, Tooltip } from '@mui/material';
import axios from 'axios';
import get from 'lodash/get';
import pick from 'lodash/pick';
import truncate from 'lodash/truncate';
import { z } from 'zod';
import { FieldFactory } from '@/classes';
import StatusChip from '@/components/Shared/StatusChip';
import TextButton from '@/components/Text/TextButton';
import { PARTY_COLORS } from '@/constants';
import { useDialogs } from '@/contexts/DialogContext';
import { useOrderableApiUrl } from '@/hooks/useApiSegment';
import {
  OrderItem,
  orderItemPayloadSchema,
  OrderItemPayload,
  genericModelReferenceSchema,
} from '@/types';
import curr from '@/utils/curr';
import CompareVendors from './CompareVendors';
import OrderItemProduct from './OrderItemProduct';
import OrderItemQtys from './OrderItemQtys';
import SizeSelector from './SizeSelector';

const ToggleIconButton = styled(IconButton, {
  shouldForwardProp: (p) => p !== 'active' && p !== 'disabled',
})<{ active?: boolean; disabled?: boolean }>(({ theme, active, disabled }) => ({
  color: active ? theme.palette.secondary.main : theme.palette.grey[500],
  fontSize: 20,
  pointerEvents: disabled ? 'none' : 'auto',
}));

export interface BaseOrderLineProps {
  handleUpdatedItem: (i: OrderItem) => void;
  setEditing: (i: OrderItem) => void;
  onRemove: (i: OrderItem) => void;
  onDuplicate: (i: OrderItem) => void;
  onReload: () => void;
  isOrderLocked: boolean;
  isGrouped?: boolean;
}

export default function OrderItemRow({
  item: itemProp,
  setEditing,
  isGrouped,
  onRemove,
  onDuplicate,
  handleUpdatedItem,
  isOrderLocked,
  onReload,
}: {
  item: OrderItem;
} & BaseOrderLineProps) {
  const [overrides, setOverrides] = useState({});
  const item = { ...itemProp, ...overrides };
  const { prompt } = useDialogs();
  const baseUrl = useOrderableApiUrl('items');
  const onSubmit = (v: Partial<OrderItemPayload>) =>
    axios.put<OrderItem>(`${baseUrl}/${item.id}`, v).then(({ data }) => handleUpdatedItem(data));

  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: item.id,
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const onEditPrice = () => {
    prompt({
      title: 'Update Price',
      description: 'What should the unit price of this item be?',
      fields: [FieldFactory.curr('price').withSize('medium').with({ maxDecimals: 4 })],
      schema: orderItemPayloadSchema.pick({ price: true }),
      initialValues: pick(item, 'price'),
      onSubmit,
    }).then(onReload);
  };

  const onEditDiscount = () => {
    prompt({
      title: 'Update Discount',
      description: 'What should the discount for this item be?',
      fields: [FieldFactory.percent('discount').withSize('medium')],
      schema: orderItemPayloadSchema.pick({ discount: true }),
      initialValues: pick(item, 'discount'),
      onSubmit,
    }).then(onReload);
  };

  const onEditQty = () => {
    prompt({
      title: 'Edit Item Qty',
      fields: [FieldFactory.number('qty').withSize('medium')],
      schema: orderItemPayloadSchema.pick({ qty: true }),
      initialValues: pick(item, 'qty'),
      onSubmit,
    }).then(onReload);
  };

  const onEditSize = () => {
    if (!item.is_custom && item.variant) {
      prompt({
        title: 'Edit Size',
        maxWidth: 'lg',
        fields: [FieldFactory.custom('variant', SizeSelector)],
        schema: orderItemPayloadSchema.pick({ variant: true }),
        initialValues: pick(item, 'variant'),
        onSubmit,
      }).then(onReload);
    } else {
      prompt({
        title: 'Edit Size',
        fields: [FieldFactory.text('size').withSize('medium')],
        schema: orderItemPayloadSchema.pick({ size: true }),
        initialValues: pick(item, 'size'),
        onSubmit,
      }).then(onReload);
    }
  };

  const handleToggle = (field: keyof OrderItem) => () => {
    const newValue = !item[field];
    setOverrides((prev) => ({
      ...prev,
      [field]: newValue,
    }));
    onSubmit({ [field]: newValue }).catch(() => {
      setOverrides((prev) => ({
        ...prev,
        [field]: item[field],
      }));
    });
  };

  const onEdit = () => setEditing(item);
  const canEdit = !isOrderLocked && item.qty_sourced === 0;

  const onCompareVendors = () => {
    prompt({
      title: 'Compare Vendors',
      initialValues: pick(item, 'variant'),
      maxWidth: 'lg',
      fields: [FieldFactory.custom('variant', CompareVendors)],
      schema: z.object({
        variant: genericModelReferenceSchema,
      }),
      onSubmit,
    }).then(onReload);
  };

  const vendorName = truncate(get(item, 'vendor.name'), { length: 25 });

  return (
    <TableRow ref={setNodeRef} style={style}>
      <TableCell sx={{ pr: 0 }}>
        {!isOrderLocked && <DragHandle {...attributes} {...listeners} />}
      </TableCell>
      <TableCell style={{ minWidth: 400 }}>
        {!isGrouped && <OrderItemProduct item={item} />}
      </TableCell>
      <TableCell>
        {!item.is_custom && item.variant && canEdit ? (
          <Tooltip title="Compare Vendors">
            {isGrouped ? (
              <IconButton size="small" onClick={onCompareVendors}>
                <CompareArrows />
              </IconButton>
            ) : (
              <TextButton onClick={onCompareVendors}>{vendorName}</TextButton>
            )}
          </Tooltip>
        ) : (
          <span>{isGrouped ? null : vendorName}</span>
        )}
      </TableCell>
      <TableCell>
        <TextButton disabled={isOrderLocked} onClick={onEditSize}>
          {item.size || '(none)'}
        </TextButton>
      </TableCell>
      <TableCell>
        <TextButton disabled={isOrderLocked} onClick={onEditQty}>
          {item.qty}
        </TextButton>
      </TableCell>
      <TableCell>
        <TextButton disabled={isOrderLocked} onClick={onEditPrice}>
          {curr(item.price, { maxDecimals: 4 })}
        </TextButton>
      </TableCell>
      <TableCell>
        <TextButton disabled={isOrderLocked} onClick={onEditDiscount}>
          {item.discount || 0}%
        </TextButton>
      </TableCell>
      <TableCell>{curr(item.subtotal)}</TableCell>
      <TableCell>{curr(item.cost, { maxDecimals: 4 })}</TableCell>
      <TableCell>
        {item.is_purchasable && (
          <StatusChip size="small" status={item.ship_to_party} colors={PARTY_COLORS} />
        )}
      </TableCell>
      <TableCell style={{ whiteSpace: 'nowrap' }}>
        <Tooltip
          title={item.is_purchasable ? 'Item will be purchased' : 'Item should not be purchased'}
        >
          <ToggleIconButton
            active={item.is_purchasable}
            size="small"
            disabled={!canEdit}
            onClick={handleToggle('is_purchasable')}
          >
            <PurchaseIcon />
          </ToggleIconButton>
        </Tooltip>

        <Tooltip
          title={
            item.can_drop_ship
              ? 'Item can be shipped directly to customer'
              : 'Item will ship to Avail'
          }
        >
          <ToggleIconButton
            active={item.can_drop_ship}
            size="small"
            disabled={isOrderLocked}
            onClick={handleToggle('can_drop_ship')}
          >
            <PinDrop />
          </ToggleIconButton>
        </Tooltip>

        <Tooltip
          title={
            item.can_apply_designs
              ? 'Item can have designs applied'
              : 'Item cannot have designs applied'
          }
        >
          <ToggleIconButton
            active={item.can_apply_designs}
            size="small"
            disabled={isOrderLocked}
            onClick={handleToggle('can_apply_designs')}
          >
            <DesignIcon />
          </ToggleIconButton>
        </Tooltip>

        <Tooltip title={item.is_shippable ? 'Item will be shipped' : 'Item will NOT be shipped'}>
          <ToggleIconButton
            active={item.is_shippable}
            size="small"
            disabled={isOrderLocked || item.qty_shipped !== 0}
            onClick={handleToggle('is_shippable')}
          >
            <ShipIcon />
          </ToggleIconButton>
        </Tooltip>

        <Tooltip
          title={
            item.show_on_invoice ? 'Item will show on invoice' : 'Item will be hidden from invoice'
          }
        >
          <ToggleIconButton
            active={item.show_on_invoice}
            size="small"
            disabled={isOrderLocked}
            onClick={handleToggle('show_on_invoice')}
          >
            <ListIcon />
          </ToggleIconButton>
        </Tooltip>
      </TableCell>
      <TableCell sx={{ py: 0, verticalAlign: 'middle' }}>
        <OrderItemQtys item={item} />
      </TableCell>
      <TableCell style={{ whiteSpace: 'nowrap' }}>
        {!isOrderLocked && (
          <Tooltip title="Edit">
            <IconButton size="small" onClick={onEdit}>
              <EditIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        )}

        {!isOrderLocked && (
          <Tooltip title="Duplicate">
            <IconButton size="small" onClick={() => onDuplicate(item)}>
              <DuplicateIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        )}

        {canEdit && (
          <Tooltip title="Delete Line">
            <IconButton size="small" onClick={() => onRemove(item)}>
              <DeleteIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        )}
      </TableCell>
    </TableRow>
  );
}
