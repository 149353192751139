import { ChangeEvent } from 'react';
import { Checkbox } from '@mui/material';
import { createColumnHelper } from '@tanstack/react-table';
import ColoredCurrency from '@/components/Shared/ColoredCurrency';
import PaginatedTable from '@/components/Shared/PaginatedTable';
import ArrayBullets from '@/components/Text/ArrayBullets';
import Text from '@/components/Text/Text';
import { getAssociatedLinkForEntry } from '@/resources/ledgerEntries';
import { LedgerEntry } from '@/types';
import curr from '@/utils/curr';
import { formatDate } from '@/utils/dates';

const columnHelper = createColumnHelper<LedgerEntry>();

export default function ReconciliationTable({
  entries,
  selected,
  onSelect,
  setSelected,
}: {
  entries: LedgerEntry[];
  selected: number[];
  onSelect: (e: ChangeEvent<HTMLInputElement>, id: number) => void;
  setSelected: (entries: number[]) => void;
}) {
  const handleCheckAll = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      setSelected(entries.map((entry) => entry.id));
    } else {
      setSelected([]);
    }
  };

  return (
    <PaginatedTable
      initialRowsPerPage={1000}
      initialSortBy={{ id: 'transaction_date', desc: false }}
      rows={entries}
      columns={[
        columnHelper.display({
          id: 'checkbox',
          header: () => (
            <Checkbox
              checked={selected.length === entries.length}
              indeterminate={selected.length !== entries.length && selected.length > 0}
              onChange={handleCheckAll}
            />
          ),
          cell: ({ row }) => (
            <Checkbox
              checked={selected.includes(row.original.id)}
              onChange={(e) => onSelect(e, row.original.id)}
            />
          ),
        }),
        columnHelper.accessor('associated_id', {
          header: 'Transaction',
          cell: ({ row: { original: entry } }) => (
            <a
              target="_blank"
              href={getAssociatedLinkForEntry(entry)}
              style={{ textDecoration: 'none' }}
              rel="noreferrer"
            >
              <Text
                primary={entry.description}
                secondary={
                  entry.associated_type === 'transaction' && entry.associated ? (
                    <ArrayBullets
                      elements={[entry.associated.vendor?.name, entry.associated.payment_method]}
                    />
                  ) : null
                }
              />
            </a>
          ),
        }),
        columnHelper.accessor('transaction_date', {
          header: 'Date',
          cell: ({ getValue }) => formatDate(getValue()),
        }),
        columnHelper.accessor('debit_amount', {
          header: 'Debit Amount',
          cell: ({ getValue }) => curr(getValue()),
        }),
        columnHelper.accessor('credit_amount', {
          header: 'Credit Amount',
          cell: ({ getValue }) => curr(getValue()),
        }),
        columnHelper.accessor('amount', {
          header: 'Net Amount',
          cell: ({ getValue }) => <ColoredCurrency amount={getValue()} />,
        }),
      ]}
    />
  );
}
