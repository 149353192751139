import { SyntheticEvent, useState } from 'react';
import { AddCircle, Delete, Edit, Search } from '@mui/icons-material';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Alert,
  Box,
  Checkbox,
  CircularProgress,
  IconButton,
  InputAdornment,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemSecondaryAction,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import flow from 'lodash/flow';
import get from 'lodash/get';
import groupBy from 'lodash/groupBy';
import orderBy from 'lodash/orderBy';
import pick from 'lodash/pick';
import reject from 'lodash/reject';
import { FieldFactory } from '@/classes';
import DrawerButtons from '@/components/Form/DrawerButtons';
import OrderEmail from '@/components/Orders/OrderEmail';
import Can from '@/components/Permissions/Can';
import ClosableDrawer from '@/components/Shared/ClosableDrawer';
import WarningIcon from '@/components/Shared/WarningIcon';
import BulletSeparatedList from '@/components/Text/BulletSeparatedList';
import { useDialogs } from '@/contexts/DialogContext';
import { useHasPermission } from '@/hooks/permissions';
import { useOrderableApiUrl } from '@/hooks/useApiSegment';
import useMutateQueryData from '@/hooks/useMutateQueryData';
import { ContactPayload, CustomerContact, customerContactPayloadSchema, Order } from '@/types';
import { useOnReloadRecord, useRecord } from '@/utils/genericResource';

type Contact = Pick<
  CustomerContact,
  'id' | 'first_name' | 'last_name' | 'name' | 'email' | 'phone' | 'title' | 'department'
>;

function ContactListItemText({ contact: c, linkEmail }: { contact: Contact; linkEmail?: boolean }) {
  return (
    <div style={{ margin: '2px 0' }}>
      <Typography style={{ marginBottom: 2 }}>
        <span style={{ fontWeight: 500 }}>{c.name}</span>
        {c.title && (
          <>
            <span>&nbsp;&bull;&nbsp;</span>
            <Typography variant="body2" component="span">
              {c.title}
            </Typography>
          </>
        )}
      </Typography>

      <Typography variant="body2" color="textSecondary">
        <BulletSeparatedList>
          {c.email && <li>{linkEmail ? <OrderEmail>{c.email}</OrderEmail> : c.email}</li>}
          {c.phone && <li>{c.phone}</li>}
        </BulletSeparatedList>
      </Typography>
    </div>
  );
}

export default function ContactChooser({ isReadOnly }: { isReadOnly?: boolean }) {
  const order = useRecord<Order>();
  const customerId = order.customer.id;

  const currentContact: Contact = {
    id: 0,
    name: order.contact_name || '',
    first_name: order.contact_first_name,
    last_name: order.contact_last_name,
    email: order.contact_email,
    phone: order.contact_phone,
    title: order.contact_title,
  };
  const [query, setQuery] = useState('');
  const [open, setOpen] = useState(false);
  const [contact, setContact] = useState<Contact>();
  const [submitting, setSubmitting] = useState(false);
  const { confirm, prompt } = useDialogs();
  const baseUrl = useOrderableApiUrl();

  const setContacts = useMutateQueryData<Contact[]>(['customerContacts', customerId]);
  const { data: contacts = [], isLoading } = useQuery(
    ['customerContacts', customerId],
    () =>
      axios.get(`/api/customers/${customerId}/contacts?count=200`).then(({ data }) => data.data),
    {
      enabled: open,
    },
  );

  const onClose = () => setOpen(false);
  const onOpen = () => setOpen(true);
  const onReload = useOnReloadRecord();
  const hasPermission = useHasPermission();

  const contactFields = [
    FieldFactory.text('first_name'),
    FieldFactory.text('last_name'),
    FieldFactory.text('title'),
    FieldFactory.belongsTo('department', 'departments').withRequestParams({
      customer: customerId,
      with: 'set',
    }),
    FieldFactory.email('email'),
    FieldFactory.phone('phone'),
  ];

  const handleSubmit = (e: SyntheticEvent) => {
    e.preventDefault();
    e.stopPropagation();

    if (!contact) {
      return null;
    }

    setSubmitting(true);
    const values = {
      contact_first_name: contact.first_name,
      contact_last_name: contact.last_name,
      contact_email: contact.email,
      contact_phone: contact.phone,
      contact_title: contact.title,
    };
    return axios
      .put(baseUrl, values)
      .then(() => {
        setContact(undefined);
        setQuery('');
        onClose();
        onReload();
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const createContact = () => {
    prompt({
      title: 'Create Contact',
      fields: contactFields,
      schema: customerContactPayloadSchema,
      initialValues: {},
      onSubmit: (v) => axios.post<CustomerContact>(`/api/customers/${customerId}/contacts/`, v),
    }).then(({ data }) => {
      setContacts((prev) => [...prev, data]);
    });
  };

  const editContact = (c: CustomerContact) => {
    prompt<ContactPayload, Contact>({
      title: 'Edit Contact',
      fields: contactFields,
      initialValues: pick(c, ['first_name', 'last_name', 'title', 'department', 'email', 'phone']),
      schema: customerContactPayloadSchema,
      onSubmit: (v) =>
        axios
          .put<CustomerContact>(`/api/customers/${customerId}/contacts/${c.id}`, v)
          .then(({ data }) => data),
    }).then((contact) => {
      setContacts((prev) =>
        prev.map((p) => {
          if (p.id === contact.id) {
            return contact;
          }
          return p;
        }),
      );
    });
  };

  const deleteContact = (c: CustomerContact) => {
    confirm({
      title: 'Delete Contact',
      description: 'Are you sure you want to delete this contact?',
    }).then(() => {
      axios.delete(`/api/customers/${customerId}/contacts/${c.id}`);
      setContacts((prev) => reject(prev, ['id', c.id]));
      if (contact?.id === c.id) {
        setContact(undefined);
      }
    });
  };

  const toggleSelection = (c: CustomerContact) => {
    setContact((prev) => (prev?.id === c.id ? undefined : c));
  };

  const getInner = () => {
    if (isLoading) {
      return <CircularProgress />;
    }
    if (contacts.length === 0) {
      return <Alert severity="warning">There are no contacts set up for this customer.</Alert>;
    }

    const search = (c: Contact) => {
      if (!query) {
        return true;
      }
      return Object.values(c).join(' ').toLowerCase().includes(query);
    };

    const filteredContacts = flow(
      (contacts) => contacts.filter(search),
      (contacts) => orderBy(contacts, ['department.name', 'first_name'], ['asc', 'asc']),
      (contacts) => groupBy(contacts, (c) => get(c, 'department.name', '(No Department)')),
    )(contacts);

    return (
      <div>
        <TextField
          label="Search"
          variant="outlined"
          type="search"
          size="small"
          fullWidth
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
          inputProps={{
            type: 'search',
          }}
        />
        <Box mx={-1} py={2} style={{ overflowY: 'auto', maxHeight: 'calc(100vh - 188px)' }}>
          {Object.entries(filteredContacts).map(([department, groupedContacts]) => (
            <List
              key={department}
              subheader={<Typography variant="subtitle2">{department}</Typography>}
            >
              {groupedContacts.map((c) => (
                <ListItemButton key={c.id} dense onClick={() => toggleSelection(c)}>
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      checked={get(contact, 'id') === c.id}
                      tabIndex={-1}
                      disableRipple
                      size="small"
                    />
                  </ListItemIcon>
                  <ContactListItemText contact={c} />
                  <ListItemSecondaryAction>
                    <IconButton onClick={() => editContact(c)} size="small">
                      <Edit fontSize="small" />
                    </IconButton>
                    <IconButton onClick={() => deleteContact(c)} size="small">
                      <Delete fontSize="small" />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItemButton>
              ))}
            </List>
          ))}
        </Box>
      </div>
    );
  };

  const icons = (
    <Tooltip title="Create Contact">
      <IconButton onClick={createContact} size="large">
        <AddCircle />
      </IconButton>
    </Tooltip>
  );

  return (
    <Box display="flex" alignItems="center">
      {currentContact.name ? (
        <ContactListItemText contact={currentContact} linkEmail />
      ) : (
        <WarningIcon
          message="No Contact Specified"
          onClick={hasPermission('write:orders') ? onOpen : undefined}
        />
      )}

      {!isReadOnly && (
        <Can permission="write:orders">
          <Box ml="auto" pl={1}>
            <Tooltip title="Choose from Customer">
              <IconButton onClick={onOpen} size="large">
                <Search />
              </IconButton>
            </Tooltip>
          </Box>
        </Can>
      )}

      <ClosableDrawer
        title="Choose Contact"
        open={open}
        onClose={onClose}
        icons={icons}
        width={650}
      >
        <form onSubmit={handleSubmit}>
          {getInner()}

          <DrawerButtons>
            <LoadingButton
              type="submit"
              loading={submitting}
              disabled={!contact}
              variant="contained"
            >
              Choose
            </LoadingButton>
          </DrawerButtons>
        </form>
      </ClosableDrawer>
    </Box>
  );
}
