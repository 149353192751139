import { Warning } from '@mui/icons-material';
import { TableRow, TableCell, Typography, TableBody, Box } from '@mui/material';
import { Resource } from '@/classes';
import { useDataTable } from './DataTableContext';

export default function ErrorState({ resource }: { resource: Resource }) {
  const { visibleColumns, showCheckbox } = useDataTable();
  return (
    <TableBody>
      <TableRow>
        <TableCell colSpan={visibleColumns.length + Number(showCheckbox)}>
          <Box display="flex" alignItems="center">
            <Warning sx={{ mr: 2 }} color="error" />
            <Typography variant="body1" color="textSecondary">
              An error occurred when attempting to fetch {resource.name.toLowerCase()}. Please try
              again.
            </Typography>
          </Box>
        </TableCell>
      </TableRow>
    </TableBody>
  );
}
