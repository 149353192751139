import { useMemo, useRef } from 'react';
import { ChevronRight } from '@mui/icons-material';
import {
  Box,
  IconButton,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
} from '@mui/material';
import { flexRender, Table as TableConfig } from '@tanstack/react-table';
import uniq from 'lodash/uniq';
import TableBodyLoadingOverlay from '@/components/DataTable/TableBodyLoadingOverlay';

export default function ReactTable<T>({
  table,
  size,
  isFetching,
}: {
  table: TableConfig<T>;
  size?: 'small' | 'medium';
  isFetching?: boolean;
}) {
  const { pagination } = table.getState();
  const total = table.getCoreRowModel().rows.length;
  const tableBodyRef = useRef<HTMLTableSectionElement | null>(null);
  const hasFooter = useMemo(() => {
    return table.getFooterGroups().some((fg) => fg.headers.some((h) => h.column.columnDef.footer));
  }, [table]);

  const someCanExpand = table.getRowModel().rows.some((r) => r.getCanExpand());

  return (
    <>
      <TableContainer sx={{ position: 'relative' }}>
        <Table size={size}>
          <TableHead>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {someCanExpand && <TableCell padding="checkbox" />}
                {headerGroup.headers.map((headCell) => (
                  <TableCell
                    key={headCell.id}
                    sortDirection={headCell.column.getIsSorted()}
                    sx={{ whiteSpace: 'nowrap' }}
                    padding={headCell.id === 'checkbox' ? 'checkbox' : 'normal'}
                  >
                    {headCell.column.getCanSort() ? (
                      <TableSortLabel
                        active={Boolean(headCell.column.getIsSorted())}
                        direction={headCell.column.getIsSorted() || 'asc'}
                        onClick={headCell.column.getToggleSortingHandler()}
                      >
                        {headCell.isPlaceholder
                          ? null
                          : flexRender(headCell.column.columnDef.header, headCell.getContext())}
                      </TableSortLabel>
                    ) : (
                      flexRender(headCell.column.columnDef.header, headCell.getContext())
                    )}
                  </TableCell>
                ))}
              </TableRow>
            ))}
            <TableRow>
              <TableCell
                colSpan={table.getHeaderGroups()[0].headers.length}
                sx={{
                  p: 0,
                  position: 'relative',
                  height: 1,
                  border: 0,
                }}
              >
                {isFetching && (
                  <Box position="absolute" top={-4} right={0} left={0}>
                    <LinearProgress />
                  </Box>
                )}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody ref={tableBodyRef}>
            {table.getRowModel().rows.map((row) => {
              const bgcolor = row.getIsGrouped() ? 'action.hover' : 'background';
              return (
                <TableRow key={row.id}>
                  {someCanExpand && (
                    <TableCell padding="checkbox" sx={{ bgcolor }}>
                      {row.getCanExpand() && (
                        <IconButton
                          onClick={() => row.toggleExpanded()}
                          sx={{
                            transform: `rotate(${row.getIsExpanded() ? '90deg' : '0'})`,
                          }}
                        >
                          <ChevronRight />
                        </IconButton>
                      )}
                    </TableCell>
                  )}
                  {row.getVisibleCells().map((cell) => (
                    <TableCell
                      key={cell.id}
                      padding={cell.column.id === 'checkbox' ? 'checkbox' : undefined}
                      sx={{
                        bgcolor,
                        whiteSpace: cell.column.id === 'actions' ? 'nowrap' : 'initial',
                      }}
                    >
                      {cell.getIsAggregated()
                        ? flexRender(
                            cell.column.columnDef.aggregatedCell ?? cell.column.columnDef.cell,
                            cell.getContext(),
                          )
                        : cell.getIsPlaceholder()
                          ? null
                          : flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </TableCell>
                  ))}
                </TableRow>
              );
            })}
            {table.getRowModel().rows.length === 0 && (
              <TableRow>
                <TableCell colSpan={table.getAllColumns().length}>
                  <Typography color="textSecondary">
                    {isFetching ? 'Loading...' : 'No results were found.'}
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
          {hasFooter && (
            <TableFooter>
              {table.getFooterGroups().map((footerGroup) => (
                <TableRow key={footerGroup.id}>
                  {someCanExpand && <TableCell padding="checkbox" />}
                  {footerGroup.headers.map((header) => (
                    <TableCell key={header.id} colSpan={header.colSpan}>
                      {header.isPlaceholder
                        ? null
                        : flexRender(header.column.columnDef.footer, header.getContext())}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableFooter>
          )}
          <TableBodyLoadingOverlay
            el={tableBodyRef.current}
            show={Boolean(isFetching) && table.getRowModel().rows.length > 0}
          />
        </Table>
      </TableContainer>

      {table.getPageCount() > 1 && (
        <TablePagination
          component="div"
          count={total}
          page={pagination.pageIndex}
          onPageChange={(e, p) => table.setPageIndex(p)}
          onRowsPerPageChange={(e) => table.setPageSize(Number(e.target.value))}
          rowsPerPage={pagination.pageSize}
          rowsPerPageOptions={uniq([table.getState().pagination.pageSize, 25, 50, 100])}
        />
      )}
    </>
  );
}
