import { useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import { Box, InputAdornment, OutlinedInput } from '@mui/material';
import useDebounce from '@/hooks/useDebounce';
import useOnChangeEffect from '@/hooks/useOnChangeEffect';

export default function Search({
  value,
  onChange,
  onBlur,
}: {
  value: string;
  onChange: (s: string) => void;
  onBlur: () => void;
}) {
  const [query, setQuery] = useState(value);
  const debouncedValue = useDebounce(query, 300);

  useOnChangeEffect(() => {
    onChange(debouncedValue);
  }, [debouncedValue]);

  return (
    <Box width={280} mx={2}>
      <OutlinedInput
        id="query"
        value={query}
        onChange={(e) => setQuery(e.target.value)}
        fullWidth
        type="search"
        autoFocus
        size="small"
        inputProps={{ onBlur }}
        startAdornment={
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        }
      />
    </Box>
  );
}
