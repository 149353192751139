import { ChangeEvent, SyntheticEvent, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Tooltip,
} from '@mui/material';
import orderBy from 'lodash/orderBy';
import startCase from 'lodash/startCase';
import sumBy from 'lodash/sumBy';
import { useNavigate } from 'react-router-dom';
import RateOfChangeChip from '@/components/Reports/RateOfChangePill';
import { ReportResultsRow, ReportTypeProps } from '@/types';
import formatNumber from '@/utils/formatNumber';
import { getCompareToRow, getReportColumns } from '@/utils/reports';

export default function ReportTable({
  results,
  compareToResults,
  compareTo,
  columns,
}: ReportTypeProps) {
  const columnSettings = columns || {};
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [[sortColumn, sortDirection], setSort] = useState<[string | null, 'asc' | 'desc' | null]>([
    null,
    null,
  ]);

  const navigate = useNavigate();

  const handleChangePage = (event: SyntheticEvent | null, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const columnNames = getReportColumns(results);

  const start = page * rowsPerPage;
  let resultsToShow = results;

  if (sortColumn && sortDirection) {
    resultsToShow = orderBy(resultsToShow, [sortColumn], [sortDirection]);
  }

  resultsToShow = resultsToShow.slice(start, start + rowsPerPage);

  const getValueForCell = (key: string, value: string | number | null) => {
    const format = columnSettings[key]?.format;
    if (value == null) {
      return '-';
    }
    if (format) {
      return formatNumber(value, format);
    }
    return value;
  };

  const createClickHandler = (row: ReportResultsRow) => () => {
    if ('href' in row && typeof row.href === 'string') {
      navigate(row.href);
    }
  };

  const createSortHandler = (column: string) => () => {
    if (column !== sortColumn) {
      setSort([column, 'asc']);
    } else if (sortDirection === 'asc') {
      setSort([column, 'desc']);
    } else {
      setSort([null, null]);
    }
    setPage(0);
  };

  return (
    <div>
      <TableContainer>
        <Table size="small">
          <TableHead>
            {columnNames.map((c) => {
              const label = columnSettings[c]?.label || startCase(c);
              return (
                <TableCell key={c}>
                  <TableSortLabel
                    active={sortColumn === c}
                    direction={sortDirection || undefined}
                    onClick={createSortHandler(c)}
                  >
                    {label}
                  </TableSortLabel>
                </TableCell>
              );
            })}
          </TableHead>
          <TableBody>
            {resultsToShow.map((r, index) => {
              const compareToRow = getCompareToRow(r, compareToResults);
              return (
                <TableRow key={r.id || index} hover={!!r.href} onClick={createClickHandler(r)}>
                  {columnNames.map((c) => {
                    const cellValue = r[c];
                    const compareValue = compareToRow?.[c];
                    const cellContents = getValueForCell(c, cellValue);
                    return (
                      <TableCell key={c}>
                        {compareTo &&
                        typeof cellValue === 'number' &&
                        typeof compareValue === 'number' ? (
                          <Tooltip
                            title={
                              <RateOfChangeChip
                                number={cellValue}
                                compareTo={compareValue}
                                compareToType={compareTo}
                                format={columns?.[c]?.format || 'number'}
                              />
                            }
                          >
                            <span>{cellContents}</span>
                          </Tooltip>
                        ) : (
                          cellContents
                        )}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
          {Object.values(columnSettings).some((c) => c.total_row) && (
            <TableFooter>
              {columnNames.map((c) => (
                <TableCell key={c}>
                  {columnSettings[c]?.total_row
                    ? getValueForCell(
                        c,
                        sumBy(results, (r) => Number(r[c])),
                      )
                    : null}
                </TableCell>
              ))}
            </TableFooter>
          )}
        </Table>
      </TableContainer>
      {results.length >= 25 && (
        <TablePagination
          rowsPerPageOptions={[25, 50, 100]}
          component="div"
          count={results.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </div>
  );
}
