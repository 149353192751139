import { ReactNode } from 'react';
import { Inventory } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import { createColumnHelper } from '@tanstack/react-table';
import sumBy from 'lodash/sumBy';
import OrderLabel from '@/components/Orders/OrderLabel';
import SkuLabel from '@/components/Products/SkuLabel';
import PaginatedTable from '@/components/Shared/PaginatedTable';
import { PurchaseOrderItem } from '@/types';
import curr from '@/utils/curr';

export default function PurchaseOrderItemsTable({
  items,
  toolbarActions,
  renderActions,
}: {
  items: PurchaseOrderItem[];
  toolbarActions?: ReactNode;
  renderActions?: (i: PurchaseOrderItem) => ReactNode;
}) {
  const columnHelper = createColumnHelper<PurchaseOrderItem>();

  return (
    <PaginatedTable
      rows={items}
      grouping={['description']}
      toolbarActions={toolbarActions}
      columns={[
        columnHelper.accessor('order_id', {
          header: 'Order',
          aggregatedCell: ({ getValue }) => <OrderLabel orderId={getValue()} />,
          cell: ({ row, getValue }) => (
            <>
              <OrderLabel orderId={getValue()} />
              {row.original.is_inventory && (
                <Tooltip title="This item is going into inventory">
                  <Inventory
                    color="primary"
                    fontSize="small"
                    sx={{ verticalAlign: 'bottom', ml: 1 }}
                  />
                </Tooltip>
              )}
            </>
          ),
        }),
        columnHelper.accessor('description', {
          header: 'Description',
        }),
        columnHelper.accessor('number', {
          header: 'Number',
        }),
        columnHelper.accessor('color', {
          header: 'Color',
        }),
        columnHelper.accessor('size', {
          header: 'Size',
        }),
        columnHelper.accessor('variant.sku', {
          header: 'SKU',
          cell: ({ row }) =>
            row.original.variant ? <SkuLabel variant={row.original.variant} /> : null,
        }),
        columnHelper.accessor('qty', {
          header: 'Qty',
          aggregationFn: 'sum',
          footer: sumBy(items, 'qty').toString(),
        }),
        columnHelper.accessor('qty_received', {
          header: 'Qty Rec',
          aggregationFn: 'sum',
          footer: sumBy(items, 'qty_received').toString(),
        }),
        columnHelper.accessor('cost', {
          header: 'Cost',
          aggregationFn: 'mean',
          aggregatedCell: ({ getValue }) => curr(getValue()),
          cell: ({ getValue }) => curr(getValue()),
        }),
        columnHelper.accessor('subtotal', {
          header: 'Subtotal',
          aggregationFn: 'sum',
          aggregatedCell: ({ getValue }) => curr(getValue()),
          cell: ({ getValue }) => curr(getValue()),
        }),
        columnHelper.accessor('gl_account.name', {
          header: 'GL Account',
        }),
        columnHelper.accessor('note', {
          header: 'Note',
        }),
        columnHelper.accessor('inventory_unit_conversion', {
          header: 'Inventory Unit Conversion',
        }),
        columnHelper.display({
          id: 'actions',
          enableHiding: false,
          header: '',
          cell: ({ row }) => renderActions?.(row.original),
        }),
      ]}
      columnVisibility={{
        variant_sku: false,
        qty_received: false,
        gl_account_name: false,
        note: false,
        inventory_unit_conversion: false,
      }}
    />
  );
}
